const sampleCertifications = [
  {
    "id": "1",
    "name": "CISSP (Certified Information Systems Security Professional)",
    "organization": "(ISC)²",
    "description": "The CISSP exam evaluates expertise across eight security domains. (Think of domains as topics you need to master based on your professional experience and education.) Passing the exam proves you have the advanced knowledge and technical skills to effectively design, implement and manage a cybersecurity program.",
    "link": "https://www.isc2.org/Certifications/CISSP#",
    "language": [
      "English",
      "French",
      "German",
      "Brazilian Portuguese",
      "Spanish Modern",
      "Japanese",
      "Simplified Chinese",
      "Korean"
    ],
    "duration": "4 hours (Maximum amount of time for the CISSP CAT exam) (The non-English linear, fixed-form CISSP exam allows 6 hours to complete)",
    "type_format": "Online",
    "role_of_the_ecsf": [
      "A.5. Architecture Design",
      "B.1. Application Development",
      "B.6. ICT Systems Engineering",
      "D.1. Information Security Strategy Development",
      "E.3. Risk Management"
    ],
    "domain": [
      "Communication and Network Security",
      "Identity and access management (IAM)",
      "Security Architecture and Engineering",
      "Asset Security",
      "Security and Risk Management",
      "Security Assessment and Testing",
      "Software Security",
      "Security Operations"
    ],
    "main_topics": "8 Domains: Security and Risk Management Asset Security Security Architecture and engineering Communication and network security Identity and access management (IAM) Security Assessment and Testing Security Operations Software Development Security",
    "level": "Intermediate",
    "prerequisites": "To qualify for the CISSP, candidates must have at least five years of cumulative, paid full-time work experience in two or more of the eight domains: Domain 1. Security and Risk Management Domain 2. Asset Security Domain 3. Security Architecture and Engineering Domain 4. Communication and Network Security Domain 5. Identity and Access Management (IAM) Domain 6. Security Assessment and Testing Domain 7. Security Operations Domain 8. Software Development Security",
    "iso_17024": 1,
    "price": "665 Euros (for standard registration EMEA region). (Other prices also available https://www.isc2.org/Register-for-Exam/ISC2-Exam-Pricing)",
    "skills_group": [10, 11, 17, 24, 25],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 15:03:59",
    "created_by": 1,
    "updated": "2022-12-15 15:03:59",
    "updated_by": 1
  },
  {
    "id": "2",
    "name": "CISM: Certified Information Security Manager",
    "organization": "ISACA",
    "description": "ISACA’s Certified Information Security Manager (CISM) certification indicates expertise in information security governance, program development and management, incident management and risk management.",
    "link": "https://www.isaca.org/credentialing/cism?utm_source=google&utm_medium=cpc&utm_campaign=CertBAU&utm_content=sem_CertBAU_certification-cism-eu-product-google&cid=sem_2006843&Appeal=sem&gclid=CjwKCAjw-L-ZBhB4EiwA76YzOQaEq8MJtBHO6pRkxbpUF9kpLAGdDj2aZpVKNMlchKywazG3YvHT0RoC7tsQAvD_BwE",
    "language": [
      "English",
      "Spanish",
      "Japanese",
      "Chinese",
      "French",
      "German",
      "Italian"
    ],
    "duration": "4 hours (240 minutes). The CISM CPE policy requires the attainment of CPE hours over an annual and three-year certification period",
    "type_format": "ONLINE / TESTING CENTERS",
    "role_of_the_ecsf": [
      "E.9. Information Systems Governance",
      "E.8. Information Security Management",
      "E.3. Risk Management",
      "A.1. IS and Business Strategy Alignment",
      "C.4. Problem Management",
      "E.7. Business Change Management",
      "E.7. Business Change Management",
      "E.2. Project and Portfolio Management"
    ],
    "domain": [
      "Security and Risk Management",
      "Security and Risk Management",
      "Security and Risk Management",
      "Identity and access management (IAM)"
    ],
    "main_topics": "CISM certification proves your expertise in the following domains: Information Security Governance, Information Security Risk Management, Incident ManagementInformation Security Program",
    "level": "Advanced",
    "prerequisites": "The individual should have at least 5 years of full-time work experience in Certified Information Systems.The individual should be in a managerial position where they should be managing the entire information security processes.",
    "iso_17024": 1,
    "price": " $760 / 760 EUR",
    "skills_group": [12, 16, 22, 24],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 15:03:59",
    "created_by": 1,
    "updated": "2022-12-15 15:03:59",
    "updated_by": 1
  },
  {
    "id": "3",
    "name": "Certified in Risk and Information Systems Control",
    "organization": "ISACA",
    "description": "Certified in Risk and Information Systems Control (CRISC) reflects the latest work practices and knowledge used by CRISC practitioners, changes in the business landscape and the heightened focus on corporate governance and enhanced business resilience. Employers can rest assured that armed with CRISC, their IT team is following governance best practices and taking a proactive, agile approach to ITRM that mitigates risks and threats and optimizes resources and ROI. ",
    "link": "https://www.isaca.org/credentialing/crisc",
    "language": ["English", "Spanish", "Korean", "Chinese"],
    "duration": "The CRISC CPE policy requires the attainment of CPE hours over an annual and three-year certification period. Earn and report an annual minimum of twenty (20) CPE hours. Earn and report a minimum of one hundred and twenty (120) CPE hours for a three-year reporting cycle period. ",
    "type_format": "Online / testing centers ",
    "role_of_the_ecsf": ["C.5. Systems Management"],
    "domain": [],
    "main_topics": " Information Technology and Security, Risk Response and Reporting, Risk Enterprise, Governance Assessment. ",
    "level": "Advanced",
    "prerequisites": "Three (3) or more years of experience in IT risk management and IS control. No experience waivers or substitutions",
    "iso_17024": 1,
    "price": "$760/ 760 EUR",
    "skills_group": [4, 5, 11, 12, 14, 13, 22, 24],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 15:03:59",
    "created_by": 1,
    "updated": "2022-12-15 15:03:59",
    "updated_by": 1
  },
  {
    "id": "4",
    "name": " CompTIA Security+ Certification",
    "organization": "Comptia",
    "description": "CompTIA Security+ is a global certification that validates the baseline skills necessary to perform core security functions and pursue an IT security career. ",
    "link": "https://www.comptia.org/training/certmaster-learn/security",
    "language": ["English", "Portuguese", "Japanese", "Thai", "Vietnamese"],
    "duration": "3 years ",
    "type_format": "Online / testing centers ",
    "role_of_the_ecsf": [
      "A.5. Architecture Design",
      "E.8. Information Security Management",
      "E.3. Risk Management",
      "A.7. Technology Trend Monitoring"
    ],
    "domain": [
      "Security Operations",
      "Software Security",
      "Asset Security",
      "Communication and Network Security"
    ],
    "main_topics": "The CompTIA Security+ certification exam will verify the successful candidate has the knowledge and skills required to assess the security posture of an enterprise environment and recommend and implement appropriate security solutions; monitor and secure hybrid environments, including cloud, mobile, and IoT; operate with an awareness of applicable laws and policies, including principles of governance, risk, and compliance; identify, analyze, and respond to security events and incidents ",
    "level": "Beginner/Novice",
    "prerequisites": "CompTIA Network+ and two years of experience in IT administration with a security focus ",
    "iso_17024": 1,
    "price": "$392 / 392 Eur",
    "skills_group": [5, 13, 22],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 15:03:59",
    "created_by": 1,
    "updated": "2022-12-15 15:03:59",
    "updated_by": 1
  },
  {
    "id": "5",
    "name": "GSEC: GIAC Security Essentials Certification",
    "organization": "GIAC",
    "description": "The GIAC Security Essentials (GSEC) certification validates a practitioner's knowledge of information security beyond simple terminology and concepts. GSEC certification holders are demonstrating that they are qualified for hands-on IT systems roles with respect to security tasks.",
    "link": "https://www.giac.org/certifications/security-essentials-gsec/",
    "language": ["English"],
    "duration": "4-5 hours",
    "type_format": "Online",
    "role_of_the_ecsf": [
      "E.3. Risk Management",
      "C.5. Systems Management",
      "E.8. Information Security Management",
      "B.6. ICT Systems Engineering"
    ],
    "domain": [
      "Security and Risk Management",
      "Identity and access management (IAM)",
      "Security Operations",
      "Communication and Network Security",
      "Asset Security",
      "Security Assessment and Testing"
    ],
    "main_topics": "Active defense, defense in depth, access control & password management Cryptography: basic concepts, algorithms and deployment, and application Defensible network architecture, networking & protocols, and network security Incident handling & response, vulnerability scanning and penetration testing Linux security: structure, permissions, & access; hardening & securing; monitoring & attack detection; & security utilities Security policy, contingency plans, critical controls and IT risk management Web communication security, virtualization and cloud security, and endpoint security Windows: access controls, automation, auditing, forensics, security infrastructure, & securing network services",
    "level": "Intermediate",
    "prerequisites": "The individual should be aware of the topics like security and has a basic understanding of networking and security aspects. For individuals who are completely new to this sector, then they are advised to go through Introduction to Information Security.",
    "iso_17024": 1,
    "price": "2400$",
    "skills_group": [8, 12, 13, 17, 18, 24, 27, 29],
    "specific_skills": [],
    "specific_knowledge": [],
    "created": "2022-12-15 15:03:59",
    "created_by": 1,
    "updated": "2022-12-15 15:03:59",
    "updated_by": 1
  }
]


export default sampleCertifications
