import React from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import Course from './Course'

const CoursesList = styled.div`
  padding: 5px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : '#ececec'};
  flex-grow: 1;
  min-height: 117px;
  //border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 0px 5px 15px 0px;
`

class ListOfCourses extends React.Component {

  render(){

    const printDroppable = (column, columnType) => {
      let draggedCourse = this.props.draggedCourse ? this.props.courses[this.props.draggedCourse] : null
      let isDropDisabled = draggedCourse === null ? false :
        column.semester !== draggedCourse.semester ? true : columnType === draggedCourse.type ? false : true

      return (
        <Droppable
          droppableId={column.id}
          isDropDisabled={isDropDisabled}
        >
          {(provided, snapshot) => (
            <CoursesList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {column.courseIds.map((course, index) => {
                return <Course key={this.props.courses[course].id} course={this.props.courses[course]} index={index} removeCourse={this.props.removeCourse} openAddModal={this.props.openAddModal}
                columnId={column.id}/>
              })}
              {provided.placeholder}
            </CoursesList>
          )}
        </Droppable>
      )
    }

    return (
      <Col xs lg="6" xl="4" className="list-of-courses">
        <h3 className="section-title"> Available Courses</h3>
        <div className="content">
          <div className="content-scroll">

            <div className="buttons-container">
              <Button variant="outline-primary" onClick={()=> this.props.openAddModal(null)}>Add course</Button>
              <Button variant="outline-secondary" onClick={this.props.fetchData} disabled={this.props.sampleCoursesLoaded}>{this.props.sampleCoursesLoaded ? 'Sample courses loaded' : 'Load sample courses'}</Button>
            </div>

            <h5 className="main-title">Mandatory Courses</h5>

            <Container >
              <Row>
                <Col>

                  <h6 className="semester-title">winter semester</h6>
                  {printDroppable(this.props.columns['mandatory-w'], "mandatory")}

                </Col><Col>

                  <h6 className="semester-title">summer semester</h6>
                  {printDroppable(this.props.columns['mandatory-s'], "mandatory")}

                </Col>
              </Row>
            </Container>

            <h5 className="main-title second">Voluntary Courses</h5>

            <Container>
              <Row>
                <Col>

                  <h6 className="semester-title">winter semester</h6>
                  {printDroppable(this.props.columns['voluntary-w'], "voluntary")}

                </Col><Col>

                  <h6 className="semester-title">summer semester</h6>
                  {printDroppable(this.props.columns['voluntary-s'], "voluntary")}

                </Col>
              </Row>
            </Container>
{/*
            <h5 className="main-title second">Custom Courses</h5>

            <Container>
              <Row>
                <Col>

                  <h6 className="semester-title">winter semester</h6>
                  {printDroppable(this.props.columns['custom-w'], "custom")}

                </Col><Col>

                  <h6 className="semester-title">summer semester</h6>
                  {printDroppable(this.props.columns['custom-s'], "custom")}

                </Col>
              </Row>
            </Container>
*/}
          </div>
        </div>

      </Col>
    )
  }
}

export default ListOfCourses
