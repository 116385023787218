import React from 'react'
import {Config} from '../../config.js'
import Axios from 'axios'
import {createHash} from 'crypto'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'


const RegisterModal = ({ visible, switchLogin, onCancel, showMessage }) => {
  const [loading, setLoading] = React.useState(false)
  const [validated, setValidated] = React.useState(false)
  const [formData, setFormData] = React.useState({name: "", company: "", email: "", password: ""})

  function submit(event){
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    setValidated(false)
    setLoading(true)
    let values = JSON.parse(JSON.stringify(formData))
    values.password = createHash('sha256').update(values.password).digest('hex')

    Axios.post( Config.server.register, values, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      setLoading(false)
      if (response && response.data.error === false){
        setFormData({name: "", company: "", email: "", password: ""})
        showMessage({type: 'success', content: "Registration successful. Now you have to wait for the confirmation by Admin.", delay: 5000})
        onCancel()
      } else {
        if (response.data.message) {
          showMessage({type: 'error', content: response.data.message})
        } else {
          showMessage({type: 'error', content: "Registration failed!"})
        }
      }
    })
    .catch((error) => {
      setLoading(false)
      console.log(error)
      showMessage({type: 'error', content: "Registration failed!"})
    })
  }

  function formChange(event) {
    setFormData({...formData, [event.target.id]: event.target.value})
  }


  return (
    <Modal
      show={visible}
      onHide={onCancel}
      backdrop="static"
      centered
      className="user-modal"
      id="register-user-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Register</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <br/>
        <Form noValidate validated={validated} onSubmit={submit}>

          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3">
              Name *
            </Form.Label>
            <Col sm="9">
              <Form.Control type="name" placeholder="Enter your name" required value={formData.name} onChange={formChange}/>
              <Form.Control.Feedback type="invalid">Please enter your name.</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="company">
            <Form.Label column sm="3">
              Institution
            </Form.Label>
            <Col sm="9">
              <Form.Control type="company" placeholder="Enter your institution"  value={formData.company} onChange={formChange}/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="email">
            <Form.Label column sm="3">
              E-mail *
            </Form.Label>
            <Col sm="9">
              <Form.Control type="email" placeholder="Enter your e-mail" required value={formData.email} onChange={formChange}/>
              <Form.Control.Feedback type="invalid">Please enter a valid e-mail address.</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="password">
            <Form.Label column sm="3">
              Password *
            </Form.Label>
            <Col sm="9">
              <Form.Control type="password" placeholder="Enter new password" required value={formData.password} onChange={formChange}/>
              <Form.Control.Feedback type="invalid">Please enter your password.</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <br/>

          <Form.Group style={{textAlign: "center"}}>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <><Spinner as="span" animation="border" size="sm" role="status"/>&nbsp;&nbsp;Loading...</>
              ): ( "Register" )}
            </Button>
            &nbsp;&nbsp;
            <Button variant="outline-secondary" onClick={()=>switchLogin("login")}>
              Log In
            </Button>
          </Form.Group>

        </Form>
      </Modal.Body>

    </Modal>
  )
}

export default RegisterModal
