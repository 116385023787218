import React from 'react'
import { Config } from '../../config.js'
import Axios from 'axios'
import { Alert, Button, Modal, Spinner, Table } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import TrainingForm from '../trainings/TrainingForm.js'

class Trainings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: null,
      isLoading: false,
      deleteModal: null,
      showForm: false,
    }
  }

  // delete row from the table
  deleteRow = () => {
    this.setState({deleteModal: null}) //close confirm modal
    Axios.post( Config.server.deleteTraining, {...this.props.authData, id: this.state.deleteModal}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        let responseData = response.data
        if (!responseData.error) {
          this.props.showMessage({type: 'success', content: "Data updated successfully."})
          this.props.getPublicData()
        } else {
          this.props.showMessage({type: 'error', content: "Something went wrong!"})
          console.log(responseData.message)
        }
      })
      .catch((error) => {
        console.log(error)
        this.props.showMessage({type: 'error', content: "Unable to connect to database."})
      })
  }


  render(){
    const { userDataMerged, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, authData, getPublicData } = this.props
    const { formData, showForm, isLoading, deleteModal } = this.state

    // sort and prepare db data to display in the table
    const data = this.props.dbTrainings
    if (Array.isArray(this.props.dbTrainings)) data.sort((a, b) => a.organizer.localeCompare(b.organizer) || a.name.localeCompare(b.name))

    return (
      <div className="content-scroll">
        <div>
          {
            userDataMerged === null || data === null || isLoading ? (
               <div className="main_loading"><Spinner animation="border" variant="primary" /><br/>Loading...</div>
            ) : (
              !Array.isArray(data) || data === 0 ? (
                <div className="main_loading"><Alert variant="danger">Sorry, something went wrong.</Alert></div>
              ) : (
                data.length === 0 ? (
                  <div className="main_loading"><Alert variant="info">No data in the database.</Alert></div>
                ) : (

                  showForm === true ? (

                    <TrainingForm saveLocally={null} formData={formData} userDataMerged={userDataMerged} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} authData={authData} hideForm={()=>this.setState({showForm: false, formData: null})} showMessage={this.props.showMessage} getPublicData={getPublicData}></TrainingForm>

                  ) : (

                    <div className="overview">
                      <h4>Trainings Overview</h4>
                      <Button size="sm" variant="outline-primary" className="header-button" onClick={()=>this.setState({showForm: true})}>+ Add Training</Button>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Organizer</th>
                            <th>Training</th>
                            <th className="action-column">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          data.map((training, index) => {
                            return(
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>{training.organizer}</td>
                                <td>{training.name}</td>
                                <td className="actionTableColumn">
                                  <EditOutlinedIcon fontSize="small" className="editIcon" data-for={"Tooltip-action-edit"} data-tip onClick={()=> this.setState({formData: training, showForm: true})}/>
                                  <ReactTooltip id={"Tooltip-action-edit"} place="top" effect="solid">
                                    Edit record
                                  </ReactTooltip>
                                  <DeleteOutlineIcon fontSize="small" className="deleteIcon" data-for={"Tooltip-action-delete"} data-tip onClick={()=> this.setState({deleteModal: training.id})} />
                                  <ReactTooltip id={"Tooltip-action-delete"} place="top" effect="solid">
                                    Delete record
                                  </ReactTooltip>
                                </td>
                              </tr>
                            )
                          })
                        }
                        </tbody>
                      </Table>
                      <Modal
                        show={deleteModal !== null}
                        onHide={()=>this.setState({deleteModal: null})}
                        centered
                        className="delete-row-modal"
                      >
                      <Modal.Header closeButton>
                        <Modal.Title>Confirm delete</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p><HelpOutlineIcon/>Are you sure you want to delete this training?</p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={()=> this.setState({deleteModal: null})}>No</Button>
                        <Button variant="primary" onClick={this.deleteRow}>Yes</Button>
                      </Modal.Footer>
                      </Modal>
                    </div>
                  )
                )
              )
            )
          }
        </div>
      </div>
    )

  }
}

export default Trainings
