import React from 'react'
import { Col, Badge, Button, Form, Row } from 'react-bootstrap'
import { Config } from '../../config'
import Axios from 'axios'
import ReactTooltip from 'react-tooltip'
import SkillSelectionModal from '../admin/SkillSelectionModal'
import { euLanguages, roles_of_the_ecsf, domains } from '../utils'

class CertificationForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          formData: this.initForm(props.formData),
          isLoading: false,
          formValidated: false,
          deleteModal: null,
          showSkillsModal: false
        }
      }

    // if no data passed by parent init empty form
    initForm = (formData) => {
        const emptyForm = {
            name : "",
            organization: "",
            description : "",
            link : "",
            language : [],
            duration : "",
            type_format : "",
            role_of_the_ecsf: [],
            domain : [],
            main_topics : "",
            level : "",
            prerequisites : "",
            iso_17024 : 0,
            price : "",
            skills_group: [],
            specific_skills: [],
            specific_knowledge: []
          }

        if (formData === null) {
            return emptyForm
        } else {
            return formData
        }
    }

    // save form data of new or edited table row
    saveForm = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.setState({formValidated: true})

        const form = event.currentTarget
        if (form.checkValidity() === false) {
        return
        }
        this.setState({isLoading: true, formValidated: false, })

        if (typeof this.props.saveLocally === 'function') {
            // only return data locally to parent
            this.props.saveLocally(this.state.formData)
            this.props.hideForm()
        } else {
            // prepare data to send to db
            let values = JSON.parse(JSON.stringify(this.state.formData))
            delete values.created
            delete values.created_by
            delete values.updated
            delete values.updated_by
            values.language = JSON.stringify(values.language)
            values.role_of_the_ecsf = JSON.stringify(values.role_of_the_ecsf)
            values.domain = JSON.stringify(values.domain)
            values.skills_group = JSON.stringify(values.skills_group)
            values.specific_skills = JSON.stringify(values.specific_skills)
            values.specific_knowledge = JSON.stringify(values.specific_knowledge)

            Axios.post( this.state.formData.hasOwnProperty('id') ? Config.server.editCertification : Config.server.addCertification, {...this.props.authData, ...values}, {headers: { 'Content-Type': 'application/json' }})
            .then((response) => {
                let responseData = response.data
                if (!responseData.error) {
                this.setState({isLoading: false, formData: null})
                this.props.showMessage({type: 'success', content: "Data updated successfully."})
                this.props.getPublicData()
                } else {
                this.setState({isLoading: false})
                this.props.showMessage({type: 'error', content: "Something went wrong!"})
                console.log(responseData.message)
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({isLoading: false})
                this.props.showMessage({type: 'error', content: "Unable to connect to database."})
            })
        }
    }

    // add new item to array field of the form
    addItemTo = (variable) => {
        let newData = JSON.parse(JSON.stringify(this.state.formData))
        newData[variable].push("")
        this.setState({formData: newData})
    }

    // delete item from array field of the form
    deleteItemFrom = (variable, id) => {
        let newData = JSON.parse(JSON.stringify(this.state.formData))
        newData[variable].splice(id,1)
        this.setState({formData: newData})
    }

    // cancel and hide form
    cancelUpload = () => {
        //this.setState({formData: null, validated: false})
        this.props.hideForm()
    }

    // catch form change event and save new data
    changeFormData = (event, arrayId) => {
        //copy state formdata object to new temporary variable
        let newData = JSON.parse(JSON.stringify(this.state.formData))
        if (event.target.id.includes("language")) {
        // save as array to language
        newData.language[arrayId] = event.target.value
        } else if (event.target.id.includes("role")) {
        // save as array to role_of_the_ecsf
        newData.role_of_the_ecsf[arrayId] = event.target.value
        } else if (event.target.id.includes("domain")) {
        // save as array to domain
        newData.domain[arrayId] = event.target.value
        } else if (event.target.id.includes("iso_17024")) {
        // toggle between 0 and 1
        newData.iso_17024 = 1 - newData.iso_17024
        } else {
        // save as string
        newData[event.target.id] = event.target.value
        }
        // store updated state object to state
        this.setState({formData: newData})
    }

    render(){
        const { userDataMerged, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, saveLocally } = this.props
        const { formValidated, formData, showSkillsModal } = this.state

        // show user name who edited the data row in the table; hide if unknown or error
        const userLabel = (userId) => {
          if ( Array.isArray(userDataMerged) && userDataMerged.length ){
          let userName = userDataMerged.find(x => x.id === userId).name
          return userName ? " by " + userName : ""
          } else {
          return ""
          }
        }

        // helper for display selected skills/knowledge by each skill group
        const selectedSkillsList = (category, groupId) => {
          let output = []
          if (category === "skills") {
            rsSkillsGroup.find(i => i.id === groupId).specific_skills.forEach((skillId, index) => {
              if (formData.specific_skills.includes(skillId)) {
                output.push(<li key={index}>{rsSpecificSkills.find(x => x.id === skillId).name}</li>)
              }
            })
          } else {
            rsSkillsGroup.find(i => i.id === groupId).specific_knowledge.forEach((knowledgeId, index) => {
              if (formData.specific_knowledge.includes(knowledgeId)) {
                output.push(<li key={index}>{rsSpecificKnowledge.find(x => x.id === knowledgeId).name}</li>)
              }
            })
          }
          return output.length ? <ul>{output}</ul> : <div className="empty">none</div>
        }

    return (
        <div className="training_form">

        {typeof saveLocally !== 'function' ? (<h4>{formData.hasOwnProperty("created") ? "Edit certification" : "Add new certification"}</h4>) : null}

        <Form noValidate validated={formValidated} onSubmit={this.saveForm} >

        <Form.Group as={Row} controlId="name">
            <Form.Label column sm={4}>Certification title *</Form.Label>
            <Col sm={8}>
            <Form.Control required value={formData.name} onChange={this.changeFormData}/>
            <Form.Control.Feedback type="invalid">
                Please provide a valid name of the certification.
            </Form.Control.Feedback>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="organization">
            <Form.Label column sm={4}>Organization *</Form.Label>
            <Col sm={8}>
            <Form.Control required value={formData.organization} onChange={this.changeFormData}/>
            <Form.Control.Feedback type="invalid">
                Please provide a valid name of the organization.
            </Form.Control.Feedback>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="description">
            <Form.Label column sm={4}>Description *</Form.Label>
            <Col sm={8}>
            <Form.Control required value={formData.description} onChange={this.changeFormData}/>
            <Form.Control.Feedback type="invalid">
                Please provide a description.
            </Form.Control.Feedback>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="duration">
            <Form.Label column sm={4}>Duration (hours) *</Form.Label>
            <Col sm={8}>
            <Form.Control required value={formData.duration} onChange={this.changeFormData}/>
            <Form.Control.Feedback type="invalid">
                Please provide a valid duration.
            </Form.Control.Feedback>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId={"type_format"}>
            <Form.Label column sm={4}>Format</Form.Label>
            <Col sm={8}>
            <Form.Control as="select" value={formData.type_format} onChange={this.changeFormData}>
                <option></option>
                <option>Face-to-face</option>
                <option>Online</option>
                <option>Hybrid</option>
            </Form.Control>
            </Col>
        </Form.Group>

        {formData.role_of_the_ecsf.map((item, indx) => {
            return (
            <Form.Group as={Row} key={indx}>
                <Form.Label column sm={4} >{indx === 0 ? "e-Competences (from e-CF)" : ""}</Form.Label>
                <Col sm={5}>
                <Form.Control as="select" id={"role-"+(indx+1)} key={indx} value={item} required onChange={(e)=>this.changeFormData(e, indx)}>
                <option></option>
                { roles_of_the_ecsf.map((item) => {
                    return (
                        <option key={item}>{ item }</option>
                    )
                })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please provide a valid role or delete this row.
                </Form.Control.Feedback>
                </Col>
                <Col sm={3} className="row-delete-button">
                <Button variant="outline-danger" size="sm" onClick={()=>this.deleteItemFrom('role_of_the_ecsf', indx)}>Delete role</Button>
                </Col>
            </Form.Group>
            )
        })}

        <Form.Group as={Row}>
            <Form.Label column sm={4} >{formData.role_of_the_ecsf.length === 0 ? "e-Competences (from e-CF)" : ""}</Form.Label>
            <Col sm={8} className="row-button">
            <Button variant="outline-success" size="sm" onClick={()=>this.addItemTo('role_of_the_ecsf')}>Add e-Competence</Button>
            </Col>
        </Form.Group>

        {formData.domain.map((item, indx) => {
            return (
            <Form.Group as={Row} key={indx}>
                <Form.Label column sm={4} >{indx === 0 ? "Domain" : ""}</Form.Label>
                <Col sm={5}>
                <Form.Control as="select" id={"domain-"+(indx+1)} key={indx} value={item} required onChange={(e)=>this.changeFormData(e, indx)}>
                <option></option>
                { domains.map((item) => {
                    return (
                        <option key={item}>{ item }</option>
                    )
                })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please provide a valid domain or delete this row.
                </Form.Control.Feedback>
                </Col>
                <Col sm={3} className="row-delete-button">
                <Button variant="outline-danger" size="sm" onClick={()=>this.deleteItemFrom('domain', indx)}>Delete domain</Button>
                </Col>
            </Form.Group>
            )
        })}

        <Form.Group as={Row}>
            <Form.Label column sm={4} >{formData.domain.length === 0 ? "Domain" : ""}</Form.Label>
            <Col sm={8} className="row-button">
            <Button variant="outline-success" size="sm" onClick={()=>this.addItemTo('domain')}>Add domain</Button>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="main_topics">
            <Form.Label column sm={4}>Main topics</Form.Label>
            <Col sm={8}>
            <Form.Control value={formData.main_topics} onChange={this.changeFormData}/>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId={"level"}>
            <Form.Label column sm={4}>Level</Form.Label>
            <Col sm={8}>
            <Form.Control as="select" value={formData.level} onChange={this.changeFormData}>
                <option></option>
                <option>Beginner/Novice</option>
                <option>Intermediate</option>
                <option>Advanced</option>
                <option>Expert</option>
            </Form.Control>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="prerequisites">
            <Form.Label column sm={4}>Prerequisites</Form.Label>
            <Col sm={8}>
            <Form.Control value={formData.prerequisites} onChange={this.changeFormData}/>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="iso_17024">
            <Form.Label column sm={4}>ISO 17024</Form.Label>
            <Col sm={8} className="row-button">
            <Form.Check
                type='checkbox'
                checked={(formData.iso_17024 === 1) ? true : false}
                onChange={this.changeFormData}
            />
            </Col>
        </Form.Group>

        {formData.language.map((item, indx) => {
            return (
            <Form.Group as={Row} key={indx}>
                <Form.Label column sm={4} >{indx === 0 ? "Language *" : ""}</Form.Label>
                <Col sm={5}>
                <Form.Control as="select" id={"language-"+(indx+1)} key={indx} value={item} required onChange={(e)=>this.changeFormData(e, indx)}>
                <option></option>
                { euLanguages.map((item) => {
                    return (
                        <option key={item}>{ item }</option>
                    )
                })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                Please provide a valid language or delete this row.
                </Form.Control.Feedback>
                </Col>
                <Col sm={3} className="row-delete-button">
                <Button variant="outline-danger" size="sm" onClick={()=>this.deleteItemFrom('language', indx)}>Delete language</Button>
                </Col>
            </Form.Group>
            )
        })}

        <Form.Group as={Row}>
            <Form.Label column sm={4} >{formData.language.length === 0 ? "Language" : ""}</Form.Label>
            <Col sm={8} className="row-button">
            <Button variant="outline-success" size="sm" onClick={()=>this.addItemTo('language')}>Add language</Button>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId={"price"}>
                <Form.Label column sm={4}>Price *</Form.Label>
                <Col sm={4}>
                <Form.Control type="number" isInvalid={formData.price !=='' && formData.price < 0} required value={formData.price} onChange={this.changeFormData}/>
                <Form.Control.Feedback type="invalid">
                    Please provide valid price (could be 0 if free).
                </Form.Control.Feedback>
                </Col>
                <Form.Label column sm={4}>€</Form.Label>
            </Form.Group>

        {formData.skills_group.length ? (
            <Form.Group as={Row} >
            <Form.Label column sm={4}>{formData.skills_group.length ? "REWIRE Skills" : ""}</Form.Label>
            <Col sm={8} >
                <div className="skills-list">
                {formData.skills_group.sort((a, b) => rsSkillsGroup.find(i => i.id === a).name.localeCompare(rsSkillsGroup.find(i => i.id === b).name)).map((groupId) => {
                let color = rsSkillsGroup.find(i => i.id === groupId).color
                return (
                    <span key={groupId}>
                    <Badge data-tip data-for={"Tooltip-skillgroup-" + groupId} style={{backgroundColor: color === "transparent" ? "#fafafa" : color}}
                    >
                        {rsSkillsGroup.find(i => i.id === groupId).name}
                    </Badge>
                    <ReactTooltip className="tooltip" id={"Tooltip-skillgroup-" + groupId} place="top" effect="solid">
                        <h5>{rsSkillsGroup.find(i => i.id === groupId).name}</h5>
                        <h6>Included skills:</h6>
                        {selectedSkillsList("skills", groupId)}
                        <h6>Included knowledge:</h6>
                        {selectedSkillsList("knowledge", groupId)}
                    </ReactTooltip>
                    </span>
                )
                })}
                </div>
            </Col>
            </Form.Group>
        ) : null}

        <Form.Group as={Row}>
            <Form.Label column sm={4}>{formData.skills_group.length ? "" : "REWIRE Skills"}</Form.Label>
            <Col sm={8} className="row-button">
            <Button variant="outline-success" size="sm" onClick={()=>this.setState({showSkillsModal: true})}>Choose</Button>
            </Col>
        </Form.Group>

        <Form.Group as={Row} controlId={"link"}>
            <Form.Label column sm={4}>Link</Form.Label>
            <Col sm={8}>
            <Form.Control value={formData.link} onChange={this.changeFormData}/>
            </Col>
        </Form.Group>

        {formData.hasOwnProperty("created") ? (<>
            <Form.Group as={Row} >
            <Form.Label column sm={4}>Created</Form.Label>
            <Form.Label column sm={8} id="created">{formData.created + " CET" + userLabel(formData.created_by)}</Form.Label>
            </Form.Group>

            <Form.Group as={Row} >
            <Form.Label column sm={4}>Updated</Form.Label>
            <Form.Label column sm={8} id="updated">{formData.updated + " CET" + userLabel(formData.updated_by)}</Form.Label>
            </Form.Group>
        </>) : ""}

        <div className="upload_buttons">
            <Button variant="danger" onClick={this.cancelUpload}>Cancel</Button>
            <Button type="submit" variant="success" >Confirm</Button>
        </div>

        </Form>

        <SkillSelectionModal rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} showSkillsModal={showSkillsModal} hideSkillModal={()=>this.setState({showSkillsModal: false})} formData={formData} saveFormData={(newData)=>this.setState({formData: {...formData, ...newData}})}/>
        </div>
    )
  }
}

export default CertificationForm
