import React from 'react'
import { Col, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
// import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts'

import enisaProfilesMap from "./initStructure/enisaProfilesMap"


class Statistics extends React.Component {

  render(){

    const { certifications, rsSkillsGroup, /*rsSpecificSkills, rsSpecificKnowledge*/ } = this.props

    const selectedSkillIds = () => {
      let tmp = []
      certifications.forEach(certification => {
        certification.skills_group.forEach(skillId => {
          tmp = tmp.concat(skillId)
        })
      })
      return [...new Set(tmp)].sort((a,b) => {return a - b})
    }

    const enisaProfileCoverage = () => {
      return enisaProfilesMap.map(profile => {
        let included = []
        let missing = []
        profile.skillIds.forEach(skillId => {
          if (selectedSkillIds().includes(skillId)) {
            included.push(skillId)
          } else {
            missing.push(skillId)
          }
        })
        return {
          coverage: parseInt((included.length/profile.skillIds.length*100).toFixed(0)),
          name: profile.name,
          included: included,
          missing: missing
        }
      })
    }

    const median = (arr) => {
      const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b)
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
    }

    return (
      <Col className="statistics" lg ="12" xl="4">
        <h3 className="section-title"> Statistics</h3>
        <div className="content">

          {certifications.length > 0 ? (
          <div className="content-scroll">

            <h6>ENISA Profiles coverage: </h6>
            <Table bordered striped className="profiles-overview" size="sm">
              <thead>
                <tr>
                  <th>Profile Name</th><th>Coverage</th>
                </tr>
              </thead>
              <tbody>
                {enisaProfileCoverage().map((profile, i) =>
                  <tr key={i}><td>{profile.name}</td>
                    <OverlayTrigger
                      transition={false}
                      key={i}
                      placement={"top"}
                      overlay={
                        <Tooltip className="tooltip">
                          <h5> {profile.name}</h5>
                          <h6> Included skills:</h6>
                          <ul>{profile.included.length ? profile.included.map((skillId, k) => <li key={k}>{rsSkillsGroup.find((p) => p.id === skillId).name}<br/></li>) : "none"}</ul>
                          <h6>Missing skills:</h6>
                          <ul>{profile.missing.length ? profile.missing.map((skillId, k) => <li key={k}>{rsSkillsGroup.find((p) => p.id === skillId).name}<br/></li>) : "none"}</ul>
                        </Tooltip>
                       }
                    >
                      {({ ref, ...triggerHandler }) => (
                        <td {...triggerHandler} ref={ref} key={i} className={profile.coverage === 100 ? 'green' : 'normal'}>{profile.coverage} %</td>
                      )}
                    </OverlayTrigger>
                  </tr>
                )}
              </tbody>
              <thead>
                <tr><td>Result (median)</td><td>{median(enisaProfileCoverage().map(profile=>profile.coverage))} %</td></tr>
              </thead>
            </Table>

            {/*
            <h6>ENISA profiles overview: </h6>

            {enisaProfilesMap.map((item, index) =>
              <div key={index}>
                <h5>{item.profile}</h5>
                <ul>
                  {item.skills.map((skillId, idx) =>
                    <li key={idx}>{rsSkillsGroup[skillId].name}</li>
                  )}
                </ul>
              </div>
            )}
            */}

          </div>
          ) : (
            <div className="empty-data">
              <font className="header">There are no certifications yet.</font><br/>Drag some course to your certification list.
            </div>
          )}
        </div>

      </Col>
    )
  }
}

export default Statistics
