import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ReactTooltip from 'react-tooltip'

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDragDisabled
      ? 'lightgrey'
      : props.isDragging
        ? 'lightgreen'
        : 'white'};
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`

class Certification extends React.Component {

  render() {
    const certification = this.props.certification
    const isDragDisabled = false

    return (
      <Draggable
        draggableId={certification.id}
        index={this.props.index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            isDragDisabled={isDragDisabled}
          >

            <div className="course">
              <div className="rightBtn" onClick={()=>this.props.removeCertification({
                id: certification.id,
                position: this.props.columnId
              })}>
                <ReactTooltip id={"Tooltip-action-remove"} place="top" effect="solid">
                {this.props.columnId === "available" ? "Permanently remove" : "Move back to the certification list"}
                </ReactTooltip>
                {this.props.columnId === "available" ? <DeleteForeverIcon data-tip data-for={"Tooltip-action-remove"} color="action"/> : <ClearIcon data-tip data-for={"Tooltip-action-remove"} color="action"/>}
              </div>

              <div className="leftBtn" onClick={()=>this.props.openAddModal(certification.id, this.props.columnId)}>
                <EditIcon color ="action" fontSize="small" className="editIcon" data-for={"Tooltip-action-edit"} data-tip/>
                <ReactTooltip id={"Tooltip-action-edit"} place="top" effect="solid">
                  Edit
                </ReactTooltip>
              </div>
              <div className="semester">certification
              </div>
      		    <div className="title">{certification.name}</div>
              <div className="area_topics">
                {certification.organization} | {certification.level}
              </div>
            {/*<div className="credits">ECTS {certification.credits}</div>*/}
            </div>
          </Container>
        )}
      </Draggable>
    )
  }
}

export default Certification
