import React from 'react'
import { Config } from '../../config.js'
import Axios from 'axios'
import { Alert, Badge, Button, Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'

import SkillSelectionModal from './SkillSelectionModal.js'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'


class StudyPrograms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: null,
      isLoading: false,
      formValidated: false,
      deleteModal: null,
      showSkillsModal: false
    }
  }

  // delete row from the table
  deleteRow = () => {
    this.setState({deleteModal: null}) //close confirm modal
    Axios.post( Config.server.deleteStudyProgram, {...this.props.authData, id: this.state.deleteModal}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        let responseData = response.data
        if (!responseData.error) {
          this.props.showMessage({type: 'success', content: "Data updated successfully."})
          this.props.getPublicData()
        } else {
          this.props.showMessage({type: 'error', content: "Something went wrong!"})
          console.log(responseData.message)
        }
      })
      .catch((error) => {
        console.log(error)
        this.props.showMessage({type: 'error', content: "Unable to connect to database."})
      })
  }

  // save form data of new or edited table row
  saveForm = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({formValidated: true})

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    this.setState({isLoading: true, formValidated: false, })

    // prepare data to send to db
    let values = JSON.parse(JSON.stringify(this.state.formData))
    delete values.created
    delete values.created_by
    delete values.updated
    delete values.updated_by
    values.language = JSON.stringify(values.language)
    values.skills_group = JSON.stringify(values.skills_group)
    values.specific_skills = JSON.stringify(values.specific_skills)
    values.specific_knowledge = JSON.stringify(values.specific_knowledge)

    Axios.post( this.state.formData.hasOwnProperty('id') ? Config.server.editStudyProgram : Config.server.addStudyProgram, {...this.props.authData, ...values}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        let responseData = response.data
        if (!responseData.error) {
          this.setState({isLoading: false, formData: null})
          this.props.showMessage({type: 'success', content: "Data updated successfully."})
          this.props.getPublicData()
        } else {
          this.setState({isLoading: false})
          this.props.showMessage({type: 'error', content: "Something went wrong!"})
          console.log(responseData.message)
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({isLoading: false})
        this.props.showMessage({type: 'error', content: "Unable to connect to database."})
      })
  }

  // add new item to array field of the form
  addItemTo = (variable) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    newData[variable].push("")
    this.setState({formData: newData})
  }

  // delete item from array field of the form
  deleteItemFrom = (variable, id) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    newData[variable].splice(id,1)
    this.setState({formData: newData})
  }

  // cancel and hide form
  cancelUpload = () => {
    this.setState({formData: null, validated: false})
  }

  // catch form change event and save new data
  changeFormData = (event, arrayId) => {
    //copy state formdata object to new temporary variable
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    if (event.target.id.includes("language")) {
      // save as array
      newData.language[arrayId] = event.target.value
    } else {
      // save as string
      newData[event.target.id] = event.target.value
    }
    // store updated state object to state
    this.setState({formData: newData})
  }


  render(){
    const { userDataMerged, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills } = this.props
    const { formValidated, formData, isLoading, deleteModal, showSkillsModal } = this.state

    // sort and prepare db data to display in the table
    const data = this.props.dbStudyPrograms
    if (Array.isArray(this.props.dbStudyPrograms)) data.sort((a, b) => a.university.localeCompare(b.university) || a.name.localeCompare(b.name) || a.degree.localeCompare(b.degree))

    // show user name who edited the data row in the table; hide if unknown or error
    const userLabel = (userId) => {
      if ( Array.isArray(userDataMerged) && userDataMerged.length ){
        let userName = userDataMerged.find(x => x.id === userId).name
        return userName ? " by " + userName : ""
      } else {
        return ""
      }
    }

    // just for form initialization purposes
    const emptyForm = {
      name : "",
      university: "",
      department : "",
      country: "",
      degree : "",
      degree_title : "",
      language : [],
      duration : "",
      cost : "",
      link : "",
      practical_lectures: "",
      description: '',
      skills_group: [],
      specific_skills: [],
      specific_knowledge: []
    }

    // helper for display selected skills/knowledge by each skill group
    const selectedSkillsList = (category, groupId) => {
      let output = []
      if (category === "skills") {
        rsSkillsGroup.find(i => i.id === groupId).specific_skills.forEach((skillId, index) => {
          if (formData.specific_skills.includes(skillId)) {
            output.push(<li key={index}>{rsSpecificSkills.find(x => x.id === skillId).name}</li>)
          }
        })
      } else {
        rsSkillsGroup.find(i => i.id === groupId).specific_knowledge.forEach((knowledgeId, index) => {
          if (formData.specific_knowledge.includes(knowledgeId)) {
            output.push(<li key={index}>{rsSpecificKnowledge.find(x => x.id === knowledgeId).name}</li>)
          }
        })
      }
      return output.length ? <ul>{output}</ul> : <div className="empty">none</div>
    }

    return (
      <div className="content-scroll">
        <div>
          {
            userDataMerged === null || data === null || isLoading ? (
               <div className="main_loading"><Spinner animation="border" variant="primary" /><br/>Loading...</div>
            ) : (
              !Array.isArray(data) || data === 0 ? (
                <div className="main_loading"><Alert variant="danger">Sorry, something went wrong.</Alert></div>
              ) : (
                data.length === 0 ? (
                  <div className="main_loading"><Alert variant="info">No data in the database.</Alert></div>
                ) : (

                  formData !== null ? (

                    <div className={/*upload_form*/"training_form"}> 
                      <h4>{formData.hasOwnProperty("created") ? "Edit study program" : "Add new study program"}</h4>

                      <Form noValidate validated={formValidated} onSubmit={this.saveForm} >

                        <Form.Group as={Row} controlId="name">
                          <Form.Label column sm={4}>Study program name *</Form.Label>
                          <Col sm={8}>
                            <Form.Control required value={formData.name} onChange={this.changeFormData}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid name of the study program.
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="university">
                          <Form.Label column sm={4}>University *</Form.Label>
                          <Col sm={8}>
                            <Form.Control required value={formData.university} onChange={this.changeFormData}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid name of the university.
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="department">
                          <Form.Label column sm={4}>Department</Form.Label>
                          <Col sm={8}>
                            <Form.Control value={formData.department} onChange={this.changeFormData}/>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="country">
                          <Form.Label column sm={4}>Country *</Form.Label>
                          <Col sm={8}>
                            <Form.Control required value={formData.country} onChange={this.changeFormData}/>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid country.
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"degree"}>
                          <Form.Label column sm={4}>Degree *</Form.Label>
                          <Col sm={8}>
                            <Form.Control required as="select" value={formData.degree} onChange={this.changeFormData}>
                              <option></option>
                              <option>Bachelor</option>
                              <option>Master</option>
                              <option>Doctoral</option>
                              <option>Post-graduate</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid degree.
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"degree_title"}>
                          <Form.Label column sm={4}>Degree title</Form.Label>
                          <Col sm={8}>
                            <Form.Control value={formData.degree_title} onChange={this.changeFormData}/>
                          </Col>
                        </Form.Group>

                        {formData.language.map((item, indx) => {
                          return (
                            <Form.Group as={Row} key={indx}>
                              <Form.Label column sm={4} >{indx === 0 ? "Language" : ""}</Form.Label>
                              <Col sm={5}>
                                <Form.Control id={"language-"+(indx+1)} key={indx} value={item} required onChange={(e)=>this.changeFormData(e, indx)}/>
                                <Form.Control.Feedback type="invalid">
                                  Please provide a valid language or delete this row.
                                </Form.Control.Feedback>
                              </Col>
                              <Col sm={3} className="row-delete-button">
                                <Button variant="outline-danger" size="sm" onClick={()=>this.deleteItemFrom('language', indx)}>Delete language</Button>
                              </Col>
                            </Form.Group>
                          )
                        })}

                        <Form.Group as={Row}>
                          <Form.Label column sm={4} >{formData.language.length === 0 ? "Language" : ""}</Form.Label>
                          <Col sm={8} className="row-button">
                            <Button variant="outline-success" size="sm" onClick={()=>this.addItemTo('language')}>Add language</Button>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"duration"}>
                          <Form.Label column sm={4}>Duration</Form.Label>
                          <Col sm={8}>
                            <Form.Control as="select" value={formData.duration} onChange={this.changeFormData}>
                              <option></option>
                              <option>1 year</option>
                              <option>2 years</option>
                              <option>3 years</option>
                              <option>4 years</option>
                              <option>other</option>
                            </Form.Control>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"cost"}>
                          <Form.Label column sm={4}>Cost per year</Form.Label>
                          <Col sm={4}>
                            <Form.Control type="number" value={formData.cost} onChange={this.changeFormData}/>
                          </Col>
                          <Form.Label column sm={4}>€</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"practical_lectures"}>
                          <Form.Label column sm={4}>Practical lectures</Form.Label>
                          <Col sm={4}>
                            <Form.Control type="number" min="0" max="100" value={formData.practical_lectures} onChange={this.changeFormData}/>
                          </Col>
                          <Form.Label column sm={4}>%</Form.Label>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"description"}>
                          <Form.Label column sm={4}>Description</Form.Label>
                          <Col sm={8}>
                            <Form.Control as="textarea" rows={3} onChange={this.changeFormData} value={formData.description}/>
                          </Col>
                        </Form.Group>

                        {formData.skills_group.length ? (
                          <Form.Group as={Row} >
                            <Form.Label column sm={4}>{formData.skills_group.length ? "REWIRE Skills" : ""}</Form.Label>
                            <Col sm={8} >
                              <div className="skills-list">
                              {formData.skills_group.sort((a, b) => rsSkillsGroup.find(i => i.id === a).name.localeCompare(rsSkillsGroup.find(i => i.id === b).name)).map((groupId) => {
                                let color = rsSkillsGroup.find(i => i.id === groupId).color
                                return (
                                  <span key={groupId}>
                                    <Badge data-tip data-for={"Tooltip-skillgroup-" + groupId} style={{backgroundColor: color === "transparent" ? "#fafafa" : color}}>
                                      {rsSkillsGroup.find(i => i.id === groupId).name}
                                    </Badge>
                                    <ReactTooltip className="tooltip" id={"Tooltip-skillgroup-" + groupId} place="top" effect="solid">
                                      <h5>{rsSkillsGroup.find(i => i.id === groupId).name}</h5>
                                      <h6>Included skills:</h6>
                                      {selectedSkillsList("skills", groupId)}
                                      <h6>Included knowledge:</h6>
                                      {selectedSkillsList("knowledge", groupId)}
                                    </ReactTooltip>
                                  </span>
                                )
                              })}
                              </div>
                            </Col>
                          </Form.Group>
                        ) : null}

                        <Form.Group as={Row}>
                          <Form.Label column sm={4}>{formData.skills_group.length ? "" : "REWIRE Skills"}</Form.Label>
                          <Col sm={8} className="row-button">
                            <Button variant="outline-success" size="sm" onClick={()=>this.setState({showSkillsModal: true})}>Choose</Button>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId={"link"}>
                          <Form.Label column sm={4}>Link</Form.Label>
                          <Col sm={8}>
                            <Form.Control value={formData.link} onChange={this.changeFormData}/>
                          </Col>
                        </Form.Group>

                        {formData.hasOwnProperty("created") ? (<>
                          <Form.Group as={Row} >
                            <Form.Label column sm={4}>Created</Form.Label>
                            <Form.Label column sm={8} id="created">{formData.created + " CET" + userLabel(formData.created_by)}</Form.Label>
                          </Form.Group>

                          <Form.Group as={Row} >
                            <Form.Label column sm={4}>Updated</Form.Label>
                            <Form.Label column sm={8} id="updated">{formData.updated + " CET" + userLabel(formData.updated_by)}</Form.Label>
                          </Form.Group>
                        </>) : ""}

                        <div className="upload_buttons">
                          <Button variant="danger" onClick={this.cancelUpload}>Cancel</Button>
                          <Button type="submit" variant="success" >Confirm</Button>
                        </div>

                      </Form>

                      <SkillSelectionModal rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} showSkillsModal={showSkillsModal} hideSkillModal={()=>this.setState({showSkillsModal: false})} formData={formData} saveFormData={(newData)=>this.setState({formData: {...formData, ...newData}})}/>
                    </div>

                  ) : (

                    <div className="overview">
                      <h4>Study Programs Overview</h4>
                      <Button size="sm" variant="outline-primary" className="header-button" onClick={()=>this.setState({formData: emptyForm})}>+ Add Study Program</Button>
                      <Table bordered hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>University</th>
                            <th>Study program</th>
                            <th className="action-column">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          data.map((program, index) => {
                            return(
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>{program.university}</td>
                                <td>
                                  <div className="badge-container">
                                    <Badge variant="secondary">{
                                      program.degree.includes("Master") ? 'Master' : (
                                        program.degree.includes("Bachelor") ? 'Bachelor' : (
                                          program.degree.includes("Doctoral") ? 'PhD' : (
                                            program.degree.includes("Post-graduate") ? 'Post-graduate' : program.degree
                                          )
                                        )
                                      )}</Badge>
                                  </div>
                                  <div className="name-container">{program.name}</div>
                                </td>
                                <td className="actionTableColumn">
                                  <EditOutlinedIcon fontSize="small" className="editIcon" data-for={"Tooltip-action-edit"} data-tip onClick={()=> this.setState({formData: program})}/>
                                  <ReactTooltip id={"Tooltip-action-edit"} place="top" effect="solid">
                                    Edit record
                                  </ReactTooltip>
                                  <DeleteOutlineIcon fontSize="small" className="deleteIcon" data-for={"Tooltip-action-delete"} data-tip onClick={()=> this.setState({deleteModal: program.id})} />
                                  <ReactTooltip id={"Tooltip-action-delete"} place="top" effect="solid">
                                    Delete record
                                  </ReactTooltip>
                                </td>
                              </tr>
                            )
                          })
                        }
                        </tbody>
                      </Table>
                      <Modal
                        show={deleteModal !== null}
                        onHide={()=>this.setState({deleteModal: null})}
                        centered
                        className="delete-row-modal"
                      >
                      <Modal.Header closeButton>
                        <Modal.Title>Confirm delete</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p><HelpOutlineIcon/>Are you sure you want to delete this study program?</p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={()=> this.setState({deleteModal: null})}>No</Button>
                        <Button variant="primary" onClick={this.deleteRow}>Yes</Button>
                      </Modal.Footer>
                      </Modal>
                    </div>
                  )
                )
              )
            )
          }
        </div>
      </div>
    )

  }
}

export default StudyPrograms
