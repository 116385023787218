const ecsfProfiles = [
  { profile: "Chief Information Security Officer (Ciso)", skills: [ 2, 6, 9, 10, 12, 13, 22, 33, 35, 42, 47, 59, 63, 68, 72, 76, 77 ], knowledges: [ 10, 20, 25, 29, 42, 56, 62, 76 ] },
  { profile: "Cyber Incident Responder", skills: [ 21, 62, 67, 84 ], knowledges: [ 19, 21, 23, 24, 36, 40, 45, 71 ] },
  { profile: "Cyber Legal, Policy & Compliance Officer", skills: [ 0, 1, 24, 28, 61, 78, 79 ], knowledges: [ 5, 8, 11, 37, 39, 48 ] },
  { profile: "Cyber Threat Intelligence Specialist", skills: [ 16, 20, 26, 55, 56, 64, 80, 83, 85 ], knowledges: [ 1, 4, 7, 12, 14, 54, 58, 66, 72] },
  { profile: "Cybersecurity Architect", skills: [ 18, 27, 31, 34, 44, 50, 70, 73, 75 ], knowledges: [ 27, 38, 49, 50, 60, 61, 64, 70, 74, 77, 79 ] },
  { profile: "Cybersecurity Auditor", skills: [ 8, 11, 15, 20, 23, 49 ], knowledges: [ 0, 16, 28, 63 ] },
  { profile: "Cybersecurity Educator", skills: [ 5, 21, 36, 37, 41, 52, 54, 65, 74 ], knowledges: [ 3, 18, 22, 30, 31, 47 ] },
  { profile: "Cybersecurity Implementer", skills: [ 14, 19, 29, 39, 43, 53, 60 ], knowledges: [ 15, 44, 46, 51, 59, 62, 67, 69 ] },
  { profile: "Cybersecurity Researcher", skills: [ 7, 19, 21, 30, 32, 71 ], knowledges: [ 26, 52, 53, 55, 73, 75, 78, 80 ] },
  { profile: "Cybersecurity Risk Manager", skills: [ 4, 17, 21, 45, 46, 58, 69 ], knowledges: [ 10, 19, 32, 43, 57, 68 ] },
  { profile: "Digital Forensics Investigator", skills: [ 20, 38, 48, 57, 82 ], knowledges: [ 17, 19, 21, 33, 34, 35, 40, 41, 45 ] },
  { profile: "Penetration Tester", skills: [ 3, 21, 25, 40, 51, 66, 81 ], knowledges: [ 2, 6, 9, 13, 58, 65 ] }
]

export default ecsfProfiles
