import React from 'react'
import { Link } from 'react-router-dom'
import {Config} from '../../config.js'
import Axios from 'axios'
import {Alert, Button, Col, Container, Form, Modal, Row, Spinner} from 'react-bootstrap'
import { DragDropContext } from 'react-beautiful-dnd'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import TimeTable from './TimeTable.js'
import ListOfCourses from './ListOfCourses.js'
import Statistics from './Statistics.js'
import SkillSelectionModal from './SkillSelectionModal.js'
import { checkColumnStructure, checkCourseStructure } from "./utils"
import dndStructure from './initStructure/dndStructure'
import areas from './initStructure/SpartaAreas'
import topics from './initStructure/SpartaTopics'
import sampleCourses from './initStructure/SampleCourses'
// import ecsfSkills from './initStructure/ecsfSkills'
// import ecsfKnowledge from './initStructure/ecsfKnowledge'

const initModalData = {
  name: '',
  id: '',
  credits: '',
  training: '',
  topics: [],
  ecsfSkillIds: [],
  ecsfKnowledgeIds: [],
  currentTopic: '',
  currentTopicPer: '',
  semester: '',
  description: '',
  skills_group: [],
  specific_skills: [],
  specific_knowledge: [],
  type: ''
}

class Curricula extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      courses: [],
      columns: dndStructure.columns,
      error: null,
      homeIndex: null,
      draggedCourse: null,
      sampleCoursesLoaded: false,
      showAddModal: false,
      addModalValidated: false,
      addModalData: JSON.parse(JSON.stringify(initModalData)),
      modalCourseId: null,
      showSkillsModal: false,
      descAnalysisLoading: false
    }
  }

  closeAddModal = () => this.setState({
    showAddModal: false, modalType: null,
    addModalData: JSON.parse(JSON.stringify(initModalData)),
    addModalValidated: false,
    modalCourseId: null
  })

  openAddModal = (courseId, columnId) => {
    let modalData = JSON.parse(JSON.stringify(initModalData))
    if (courseId !== null) {
      modalData = this.state.courses[courseId]
      modalData.currentTopic = ''
      modalData.currentTopicPer = ''
      modalData.columnId = columnId
    }
    this.setState({showAddModal: true, modalCourseId: courseId, addModalData: modalData})
  }

  changeAddModal = (event) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    if (event.target.id === "ecsfSkillIds" || event.target.id === "ecsfKnowledgeIds" || event.target.id === "skills_group") {
      if (event.target.value > 0 && !newData[event.target.id].includes(parseInt(event.target.value))) {
        newData[event.target.id].push(parseInt(event.target.value))
      }
    } else {
      newData[event.target.id] = event.target.id === "credits" || event.target.id === "currentTopic" || event.target.id === "currentTopicPer" ? parseFloat(event.target.value) : event.target.value
    }
    if (newData.name && newData.semester) {
      newData.id = newData.semester === "winter" ? "w_" : "s_"
      newData.id += newData.name.replace(/ /g,"_").toLowerCase()
    }
    if (newData.id !== this.state.modalCourseId) {
      while (typeof this.state.courses[newData.id] != "undefined"){
        newData.id += "_copy"
      }
    }
    this.setState({addModalData: newData})
  }

  submitModal = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      this.setState({addModalValidated: true})
    } else {
      if (this.state.modalCourseId === null) this.addCustomCourse(this.state.addModalData)
      else this.updateCourse(this.state.addModalData)
      this.closeAddModal()
    }
  }

  analyzeCourseDescription = () => {
    this.setState({descAnalysisLoading: true})
    Axios.post( Config.server.analyzeCourseDescription, {description: this.state.addModalData.description}, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      this.setState({descAnalysisLoading: false})
      if (response && response.data.error === false){
        let newModalData = JSON.parse(JSON.stringify(this.state.addModalData))
        newModalData.skills_group = response.data.data.prediction.skills_groups
        this.setState({addModalData: newModalData})
        this.props.showMessage({type: 'success', content: "Description analysis done."})
      } else {
        this.props.showMessage({type: 'error', content: "Something went wrong!"})
      }
    })
    .catch((error) => {
      this.setState({descAnalysisLoading: false})
      console.log(error)
      this.props.showMessage({type: 'error', content: "Something went wrong!"})
    })
  }

  addItemTo = (id) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    newData[id].push("")
    this.setState({addModalData: newData})
  }

  removeItemFrom = (arr, index) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    newData[arr].splice(index, 1)
    this.setState({addModalData: newData})
  }

  addTopic = () => {
    if (this.state.addModalData.currentTopic){
      let newData = this.state.addModalData
      newData.topics.push({
        topicId: this.state.addModalData.currentTopic - 1,
        ectsPer: this.state.addModalData.currentTopicPer ? this.state.addModalData.currentTopicPer / 100 : 1
      })
      newData.currentTopicPer = ''
      newData.currentTopic = ''
      this.setState({addModalData: newData})
    }
  }

  editTopic = (id) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    newData.currentTopic = newData.topics[id].topicId + 1
    newData.currentTopicPer = parseFloat(newData.topics[id].ectsPer)*100
    newData.topics.splice(id, 1)
    this.setState({addModalData: newData})
  }

  addCustomCourse = (input) => {
    let error = false
    let newCourse = JSON.parse(JSON.stringify(input))
    // delete newCourse.currentCompetency
    delete newCourse.currentTopic
    delete newCourse.currentTopicPer

    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    if (newCourse.semester === 'winter') {
      if (newCourse.type === 'mandatory') {
        newColumns['mandatory-w'].courseIds.push(newCourse.id)
      } else if (newCourse.type === 'voluntary') {
        newColumns['voluntary-w'].courseIds.push(newCourse.id)
      } else {
        console.log("Error: Wrong type!")
        error = true
      }
    } else if (newCourse.semester === 'summer'){
      if (newCourse.type === 'mandatory') {
        newColumns['mandatory-s'].courseIds.push(newCourse.id)
      } else if (newCourse.type === 'voluntary') {
        newColumns['voluntary-s'].courseIds.push(newCourse.id)
      } else {
        console.log("Error: Wrong type!")
        error = true
      }
    } else {
      console.log("Error: Wrong semester!")
      error = true
    }

    const newState = {
      ...this.state,
      columns: newColumns,
      courses: {
        ...this.state.courses,
        [newCourse.id]: newCourse
      }
    }

    if (!error) this.setState(newState)
  }

  updateCourse = (input) => {
    let newCourse = JSON.parse(JSON.stringify(input))
    let oldPosition = newCourse.columnId
    let newPosition = isNaN(newCourse.columnId.charAt(0)) ?
      newCourse.type + '-' + newCourse.semester.charAt(0) :
      newCourse.columnId.charAt(0) + '-' + newCourse.semester.charAt(0)
    delete newCourse.columnId
    delete newCourse.currentTopic
    delete newCourse.currentTopicPer
    const newState = JSON.parse(JSON.stringify(this.state))

    // odebraní starého
    newState.columns[oldPosition].courseIds.splice( newState.columns[oldPosition].courseIds.indexOf(this.state.modalCourseId), 1 )
    delete newState.courses[this.state.modalCourseId]

    // pridani nového
    newState.courses = {...newState.courses, [newCourse.id]: newCourse}
    newState.columns[newPosition].courseIds.push(newCourse.id)

    this.setState({courses: newState.courses, columns: newState.columns})
  }

  duplicateCourse = () => {
    let newCourse = JSON.parse(JSON.stringify(this.state.courses[this.state.modalCourseId]))
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    let newPosition = null
    Object.values(this.state.columns).some(item => {
      if(item.courseIds.includes(newCourse.id)){
        newPosition = item.id
        return true
      } else { return null }
    })
    newCourse.name += " (copy)"
    newCourse.id += "_copy"
    newColumns[newPosition].courseIds.push(newCourse.id)

     this.setState({
       courses: {...this.state.courses, [newCourse.id]: newCourse},
       columns: newColumns
     })
    this.closeAddModal()
  }

  removeForeverCourse = (course) => {
    let newState = JSON.parse(JSON.stringify(this.state))
    newState.columns[course.position].courseIds.splice( newState.columns[course.position].courseIds.indexOf(course.id), 1 )
    delete newState.courses[course.id]
    this.setState({columns: newState.columns, courses: newState.courses})
  }

  removeCourse = (course) => {
    let newPosition = course.type + '-' + course.semester.charAt(0)
    let newColumns = JSON.parse(JSON.stringify(this.state.columns))
    newColumns[course.position].courseIds.splice( newColumns[course.position].courseIds.indexOf(course.id), 1 )
    newColumns[newPosition].courseIds.push(course.id)
    this.setState({columns: newColumns})
  }

  importData = (input) => {
    try {
      const newData = JSON.parse(input)

      // ecsf is added when missing in the file
      for (const item in newData.courses) {
        if (!newData.courses[item].hasOwnProperty('ecsfKnowledgeIds')) {
          newData.courses[item].ecsfKnowledgeIds = []
        }
        if (!newData.courses[item].hasOwnProperty('ecsfSkillIds')) {
          newData.courses[item].ecsfSkillIds = []
        }
        if (!newData.courses[item].hasOwnProperty('description')) {
          newData.courses[item].description = ''
        }
        if (!newData.courses[item].hasOwnProperty('skills_group')) {
          newData.courses[item].skills_group = []
        }
      }

      // input data validation test
      let columnTest = checkColumnStructure(newData.columns, dndStructure.columns)
      let courseTest = checkCourseStructure(newData.courses, newData.columns)

      if (columnTest && courseTest) {
        this.setState({
          courses: newData.courses,
          columns: newData.columns
        })
      } else {
        this.setState({error:{message: "Wrong input file format!"}})
      }
    } catch {
      this.setState({error:{message: "Wrong input file format!"}})
    }

  }

  fetchData = () => {
    if (typeof(sampleCourses) !== "undefined" && sampleCourses !== null && sampleCourses.length !== 0){
      let sortedCourses = sampleCourses.sort((a,b) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0))

      let allCourses = []
      sortedCourses.forEach(course => {
        let newId = course.semester === "winter" ? "w_" : "s_"
        newId += course.name.replace(/ /g,"_").toLowerCase()
        if (this.state.courses[newId] === undefined) {
          course.id = newId
          allCourses[course.id] = course
        }
      })

      let newState = {
        sampleCoursesLoaded: true
      }

      if (Object.keys(allCourses).length !== 0) {
        let newCols = JSON.parse(JSON.stringify(this.state.columns))
        newCols['mandatory-w'].courseIds = [...newCols['mandatory-w'].courseIds, ...sortedCourses.filter(course => {
          return course.type === "mandatory" && course.semester === "winter"
        }).map(course => { return course.id}).filter(function (el) {return el != null})]
        newCols['mandatory-s'].courseIds = [...newCols['mandatory-s'].courseIds, ...sortedCourses.filter(course => {
          return course.type === "mandatory" && course.semester === "summer"
        }).map(course => { return course.id}).filter(function (el) {return el != null})]
        newCols['voluntary-w'].courseIds = [...newCols['voluntary-w'].courseIds, ...sortedCourses.filter(course => {
          return course.type === "voluntary" && course.semester === "winter"
        }).map(course => { return course.id}).filter(function (el) {return el != null})]
        newCols['voluntary-s'].courseIds = [...newCols['voluntary-s'].courseIds, ...sortedCourses.filter(course => {
          return course.type === "voluntary" && course.semester === "summer"
        }).map(course => { return course.id}).filter(function (el) {return el != null})]

        newState = {
          ...newState,
          courses: {...JSON.parse(JSON.stringify(this.state.courses)), ...allCourses},
          columns: newCols
        }
      }

      this.setState(newState)
    } else {
      this.setState({error:{message: "There are no courses in the database!"}})
    }
  }

  onDragStart = (start) => {
    const homeIndex = start.source.droppableId
    const draggedCourse = this.state.courses[start.draggableId].id

    this.setState({
      homeIndex,
      draggedCourse,
    })
  }

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result

    if (!destination) {
      this.setState({
        homeIndex: null,
        draggedCourse: null
      })
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      this.setState({
        homeIndex: null,
        draggedCourse: null
      })
      return
    }

    const start = this.state.columns[source.droppableId]
    const finish = this.state.columns[destination.droppableId]

    if (start === finish) {
      const newCourseIds = Array.from(start.courseIds)
      newCourseIds.splice(source.index, 1)
      newCourseIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        courseIds: newCourseIds
      }

      const newState = {
        ...this.state,
        homeIndex: null,
        draggedCourse: null,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      }

      this.setState(newState)
      return
    }

    // Moving from one list to another
    const startCourseIds = Array.from(start.courseIds)
    startCourseIds.splice(source.index, 1)
    const newStart = {
      ...start,
      courseIds: startCourseIds
    }

    const finishCourseIds = Array.from(finish.courseIds)
    finishCourseIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      courseIds: finishCourseIds
    }

    const newState = {
      ...this.state,
      homeIndex: null,
      draggedCourse: null,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }
    this.setState(newState)
  }

  saveSpecificItems = (input) => {
    let newData = JSON.parse(JSON.stringify(this.state.addModalData))
    newData.skills_group = input.skills_group
    newData.specific_skills = input.specific_skills
    newData.specific_knowledge = input.specific_knowledge
    this.setState({addModalData: newData})

  }

  render(){
    const { isLoading, columns, error, courses, addModalData, draggedCourse, showAddModal, addModalValidated, modalCourseId, sampleCoursesLoaded, showSkillsModal, descAnalysisLoading} = this.state
    const { rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills } = this.props

    // const cutLongString = (longString, newLength) => {
    //   if (longString.length > newLength) {
    //     return longString.substr(0, newLength) + "..."
    //   } else {
    //     return longString
    //   }
    // }

    // skryva již vybrané topicy ze select nabidky
    const reducedTopics = () => {
      // let output = topcs.filter((topic, index) => {
      //   return addModalData.topics.find(o => o.topicId === index) === undefined
      // })
      // return output
      return topics
    }

    const reducedSkillsGroups = () => {
      return Array.isArray(rsSkillsGroup) ? (
        rsSkillsGroup.filter(skillGroup => !addModalData.skills_group.includes(skillGroup.id))
      ) : null
    }

    // pro spusteni feedback modalu
    /*
    let activeCoursesSum =
      columns["1-w"].courseIds.length +
      columns["1-s"].courseIds.length +
      columns["2-w"].courseIds.length +
      columns["2-s"].courseIds.length +
      columns["3-w"].courseIds.length +
      columns["3-s"].courseIds.length
    */

    return (
      <div className="main">
        {error ? (
          <div className="main_loading">
            <Alert variant="danger">{error.message}</Alert>
            <div><Link to={"/"} onClick={()=>{window.location.reload()}}>Go back</Link></div>
          </div>
        ) : (
          isLoading ? (
            <div className="main_loading"><Spinner animation="border" variant="primary" /><br/>Loading</div>
          ) : (
            <Container fluid>
              <Row>
                <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                  <ListOfCourses columns={columns} courses={courses}
                    draggedCourse={draggedCourse}
                    sampleCoursesLoaded={sampleCoursesLoaded}
                    addCustomCourse={this.addCustomCourse}
                    removeCourse={this.removeForeverCourse}
                    fetchData={this.fetchData}
                    openAddModal={this.openAddModal}/>
                  <TimeTable columns={columns} courses={courses}
                    draggedCourse={draggedCourse}
                    removeCourse={this.removeCourse}
                    importData={this.importData}
                    openAddModal={this.openAddModal}/>
                  <Statistics columns={columns} courses={courses}/>
                </DragDropContext>
              </Row>
            </Container>
          )
        )}

        <Modal show={showAddModal} onHide={this.closeAddModal} backdrop="static" size="xl" animation={false} centered className={"modal-add-course"} >

          <Form noValidate validated={addModalValidated} onSubmit={this.submitModal}>

            <Modal.Header closeButton className={modalCourseId === null ? "" : " edit"}>
              <Modal.Title>{modalCourseId === null ? "Add new course" : "Edit course"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>ID</b>
                </Form.Label>
                <Col sm={8} className="vertical">
                  <Form.Label id="id">{addModalData.id}</Form.Label>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>Name</b>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" id="name" required onChange={this.changeAddModal} value={addModalData.name}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="type" >
                <Form.Label column sm={3}>
                  <b>Type</b>
                </Form.Label>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Mandatory"
                    name="type"
                    value="mandatory"
                    checked={addModalData.type === 'mandatory'}
                    onChange={this.changeAddModal}
                    required
                  />
                </Col>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Voluntary"
                    name="type"
                    value="voluntary"
                    checked={addModalData.type === 'voluntary'}
                    onChange={this.changeAddModal}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="semester" >
                <Form.Label column sm={3}>
                  <b>Semester</b>
                </Form.Label>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Winter"
                    name="semester"
                    value="winter"
                    checked={addModalData.semester === 'winter'}
                    onChange={this.changeAddModal}
                    required
                  />
                </Col>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Summer"
                    name="semester"
                    value="summer"
                    checked={addModalData.semester === 'summer'}
                    onChange={this.changeAddModal}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="training" >
                <Form.Label column sm={3}>
                  <b>Training</b>
                </Form.Label>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="training"
                    value='Yes'
                    checked={addModalData.training === 'Yes'}
                    onChange={this.changeAddModal}
                    required
                  />
                </Col>
                <Col sm={4} className="vertical ">
                  <Form.Check
                    type="radio"
                    label="No"
                    name="training"
                    value='No'
                    checked={addModalData.training === 'No'}
                    onChange={this.changeAddModal}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>ECTS Credits</b>
                </Form.Label>
                <Col sm={4}>
                  <Form.Control type="number" id="credits" required
                  onChange={this.changeAddModal} value={addModalData.credits} min="0"/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>Topics (ECTS %)</b>
                </Form.Label>
                <Col sm={4} className="vertical">
                  <Form.Control as="select" id="currentTopic"
                  onChange={this.changeAddModal} value={addModalData.currentTopic}> >
                    {['', ...reducedTopics()].map((topic, index) => {
                      return <option key={index} style={{background: topic? areas[topic.areaId].color : '', color: 'white'}} value={index}>{topic.name}</option>
                    })}
                  </Form.Control>
                </Col>
                <Col sm={2} className="vertical percents">
                  {addModalData.currentTopic !== "" ? <><Form.Control type="number" id="currentTopicPer"
                  onChange={this.changeAddModal} value={addModalData.currentTopicPer ? addModalData.currentTopicPer : 100} min="0" max="100"/>&nbsp;%</> : ""}
                </Col>
                <Col sm={2} className="vertical">
                  {addModalData.currentTopic !== "" ? <Button variant="outline-success" size="sm" disabled={!addModalData.currentTopic} onClick={this.addTopic}>Save</Button> : ""}
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Col sm={3} ></Col>
                <Col sm={8} >
                  <table><tbody>
                  {addModalData.topics.map((topic, index) => {
                    return (
                      <tr key={index}>
                        <td className="topicListPer" >{topic.ectsPer*100}&nbsp;%&nbsp;</td>
                        <td><div className="modal-badge" style={{background: areas[reducedTopics()[topic.topicId].areaId].color}}>{reducedTopics()[topic.topicId].name}</div></td>
                        <td><EditIcon color="action" className="editIcon" fontSize="small" title="Edit topic percentage" onClick={()=>this.editTopic(index)}/></td>
                        <td><DeleteForeverIcon color="action" className="deleteIcon" fontSize="small" title="Remove topic" onClick={()=>this.removeItemFrom("topics", index)}/></td>
                      </tr>
                    )
                  })}
                  </tbody></table>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>Description</b>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control as="textarea" id="description" rows={3} onChange={this.changeAddModal} value={addModalData.description}/>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Col sm={3}>&nbsp;</Col>
                <Col sm={8}>
                  <Button variant="outline-primary" size="sm" disabled={addModalData.description.length === 0 || descAnalysisLoading} onClick={this.analyzeCourseDescription}>{descAnalysisLoading ? <>Analysis in progress &nbsp;<Spinner animation="border" variant="primary" size="sm" /></> : "Analyze"}</Button>
                </Col>
              </Form.Group>

              {/* ------ skill groups ---------*/}

              {Array.isArray(reducedSkillsGroups()) ? (<>
                <Form.Group as={Row} >
                  <Form.Label column sm={3}>
                    <b>Skills Groups</b>
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control as="select" id="skills_group" onChange={this.changeAddModal} value=''>
                      {[{id:null,name:''}, ...reducedSkillsGroups()].map(skill => {
                        return (<option className="sel-option" value={skill.id} key={skill.id} title={skill.name}>{skill.name}</option>)
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} >
                  <Col sm={3} ></Col>
                  <Col sm={8} >
                    {/*descAnalysisLoading ? (
                      <table className="skill-group-list"><tbody>
                        <tr><td><Spinner animation="border" size="sm" variant="primary" /></td></tr>
                        <tr><td>Loading...</td></tr>
                      </tbody></table>
                    ) : (*/}
                      <table><tbody>
                      {addModalData.skills_group.map((skillId, index) => {
                        let color = rsSkillsGroup.find(i => i.id === skillId).color
                        return (
                          <tr key={index}>
                            <td><div className="modal-badge ecsf" style={{backgroundColor: color === "transparent" ? "#fafafa" : color}}>{rsSkillsGroup.find(a => a.id === skillId).name}</div></td>
                            <td><DeleteForeverIcon color="action" className="deleteIcon" fontSize="small" title="Remove skill group" onClick={()=>this.removeItemFrom("skills_group", index)}/></td>
                          </tr>
                        )
                      })}
                      </tbody></table>

                  </Col>
                </Form.Group>
              </>) : (
                <Form.Group as={Row} >
                  <Form.Label column sm={3}>
                    <b>Skills Groups</b>
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control as="select" id="skills_group" title="Currently disabled" disabled={true}></Form.Control>
                  </Col>
                </Form.Group>
              ) }

              {/* ------ skills a knowledge ---------*/}

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>ENISA Skills and Knowledge</b>
                </Form.Label>
                <Col sm={8} >
                  <Button variant="outline-success" size="sm" disabled={addModalData.skills_group.length > 0 ? false : true} onClick={()=>this.setState({showSkillsModal: true})}>Choose</Button>
                </Col>
              </Form.Group>

              {/* --------- puvodni skills a knowledge ------------

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>ECSF Skills</b> <Badge variant="secondary">Beta</Badge>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control as="select" id="ecsfSkillIds" onChange={this.changeAddModal} value=''>
                    {['', ...ecsfSkills].map((skill, index) => {
                      return (<option className="sel-option" value={index} key={index} title={skill}>{cutLongString(skill,100)}</option>)
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Col sm={3} ></Col>
                <Col sm={8} >
                  <table><tbody>
                  {addModalData.ecsfSkillIds.map((skillId, index) => {
                    return (
                      <tr key={index}>
                        <td><div className="modal-badge ecsf">{ecsfSkills[skillId]}</div></td>
                        <td><DeleteForeverIcon color="action" className="deleteIcon" fontSize="small" title="Remove skill" onClick={()=>this.removeSkill(index)}/></td>
                      </tr>
                    )
                  })}
                  </tbody></table>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Form.Label column sm={3}>
                  <b>ECSF Knowledge</b> <Badge variant="secondary">Beta</Badge>
                </Form.Label>
                <Col sm={8}>
                  <Form.Control as="select" id="ecsfKnowledgeIds" onChange={this.changeAddModal} value=''>
                    {['', ...ecsfKnowledge].map((knowledge, index) => {
                      return (<option className="sel-option" value={index} key={index} title={knowledge}>{cutLongString(knowledge,100)}</option>)
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row} >
                <Col sm={3} ></Col>
                <Col sm={8} >
                  <table><tbody>
                  {addModalData.ecsfKnowledgeIds.map((knowledgeId, index) => {
                    return (
                      <tr key={index}>
                        <td><div className="modal-badge ecsf">{ecsfKnowledge[knowledgeId]}</div></td>
                        <td><DeleteForeverIcon color="action" className="deleteIcon" fontSize="small" title="Remove knowledge" onClick={()=>this.removeKnowledge(index)}/></td>
                      </tr>
                    )
                  })}
                  </tbody></table>
                </Col>
              </Form.Group>
              */}

            </Modal.Body>
            <Modal.Footer>
              {modalCourseId === null ? "" : <Button variant="outline-dark" className="left" onClick={this.duplicateCourse}>Duplicate</Button>}
              <Button variant="secondary" onClick={this.closeAddModal}>Close</Button>
              <Button variant="primary" type="submit">Save</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {Array.isArray(rsSkillsGroup) && rsSkillsGroup.length ? (
          <SkillSelectionModal rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} showSkillsModal={showSkillsModal} hideSkillModal={()=>this.setState({showSkillsModal: false})} formData={addModalData} saveFormData={(newData)=>this.saveSpecificItems(newData)}/>
        ) : null}

      </div>
    )
  }

}

export default Curricula
