import React from 'react'
import {Config} from '../../config.js'
import Axios from 'axios'
import {createHash} from 'crypto'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'


const LoginModal = ({ visible, switchLogin, setUser, onCancel, showMessage }) => {
  const [loading, setLoading] = React.useState(false)
  const [validated, setValidated] = React.useState(false)
  const [formData, setFormData] = React.useState({email: "", password: ""})

  function submit(event){
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    setValidated(false)
    setLoading(true)
    let values = JSON.parse(JSON.stringify(formData))
    values.password = createHash('sha256').update(values.password).digest('hex')

    Axios.post( Config.server.login, values, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      setLoading(false)
      if (response && response.data.error === false){
        setFormData({email: "", password: ""})
        showMessage({type: 'success', content: "Now you are logged in."})
        setUser({...response.data.user, ...values})
      } else {
        if (response.data.message) {
          showMessage({type: 'error', content:
            response.data.message.includes("Incorrect e-mail") ? "Incorrect e-mail or password!" :
            response.data.message.includes("Unconfirmed") ? "We are sorry, your account is still unconfirmed. Please wait for the confirmation by Admin." :
            response.data.message.includes("Blocked") ? "Your account is blocked by Admin." : "Something went wrong!",
          duration: 5})
        } else {
          showMessage({type: 'error', content: "Something went wrong!"})
        }
      }
    })
    .catch((error) => {
      setLoading(false)
      console.log(error)
      showMessage({type: 'error', content: "Login failed!"})
    })
  }

  function formChange(event) {
    setFormData({...formData, [event.target.id]: event.target.value})
  }


  return (
    <Modal
      show={visible}
      onHide={onCancel}
      backdrop="static"
      centered
      className="user-modal"
      id="login-user-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Log In</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <br/>
        <Form noValidate validated={validated} onSubmit={submit}>

          <Form.Group controlId="email">
            <Form.Control type="email" placeholder="Enter e-mail" required value={formData.email} onChange={formChange}/>
            <Form.Control.Feedback type="invalid">Please enter a valid e-mail address.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Control type="password" placeholder="Password" required value={formData.password} onChange={formChange}/>
            <Form.Control.Feedback type="invalid">Please enter your password.</Form.Control.Feedback>
            <Form.Text className="text-muted">
              <Button variant="link" as="span" onClick={()=>switchLogin("forgot")}>Forgot password?</Button>
            </Form.Text>
          </Form.Group>

          <br/>

          <Form.Group style={{textAlign: "center"}}>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <><Spinner as="span" animation="border" size="sm" role="status"/>&nbsp;&nbsp;Loading...</>
              ): ( "Log In" )}
            </Button>
            &nbsp;&nbsp;
            <Button variant="outline-secondary" onClick={()=>switchLogin("register")}>
              Register
            </Button>
          </Form.Group>

        </Form>
      </Modal.Body>

    </Modal>
  )
}

export default LoginModal
