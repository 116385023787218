import React from 'react'
import {Config} from '../../config.js'
import Axios from 'axios'
import { Accordion, Alert, Badge, Card, Spinner } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { DataGrid } from '@material-ui/data-grid'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'



const UserManager = ({ authData, showMessage, usersData, getUsersData }) => {
  const [loading, setLoading] = React.useState(false)

  function updateUsersData(action, userId) {
    if (!loading) {
      setLoading(true)
      Axios.post( Config.server.updateUsersData, {...authData, action: action, user_id: userId}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        if (response && response.data.error === false){
          getUsersData()
          showMessage({type: 'success', content: "Updated successfully."})
          setLoading(false)
        } else {
          console.log(response.data.message)
          showMessage({type: 'error', content: 'Something went wrong!'})
          setLoading(false)
        }
      })
      .catch((error) => {
        showMessage({type: 'error', content: 'Something went wrong!'})
        setLoading(false)
        console.log("Server is unavailable")
        console.log(error)
      })
    } else {
      showMessage({type: 'warning', content: 'Wait for the previous task to complete.'})
    }
  }

  function actionColumns (type) {
    return [{
      headerName: 'Action',
      field: 'action',
      cellClassName: 'actionTableColumn',
      sortable: false,
      width: 73,
      renderCell: (record) => {return (
        type === "admins" ? (
          loading ? (
            <>
              <ArrowDownwardIcon fontSize="small" className="disabledIcon"/>
              <ReactTooltip place="top" effect="solid">
              Wait for the previous task!
              </ReactTooltip>
            </>
          ) : usersData.adminUsers.length <= 1 ? (
            <>
              <ArrowDownwardIcon fontSize="small" className="disabledIcon"/>
              <ReactTooltip place="top" effect="solid">
              At least one admin must remain!
              </ReactTooltip>
            </>
          ) : (
            <>
              <ArrowDownwardIcon fontSize="small" className="deleteIcon" data-tip onClick={()=>updateUsersData(7, record.id)}/>
              <ReactTooltip place="top" effect="solid">
              Remove admin privileges
              </ReactTooltip>
            </>
            )
              ) : type === "new" ? (
          <>
          <CheckCircleOutlineIcon fontSize="small" className={loading ? "disabledIcon" : "confirmIcon"} data-tip onClick={()=>updateUsersData(2, record.id)} />
          <ReactTooltip place="top" effect="solid">
          {loading ? "Wait for the previous task!" : "Confirm registration"}
          </ReactTooltip>
          <DeleteOutlineIcon fontSize="small" className={loading ? "disabledIcon" : "deleteIcon"} data-tip onClick={()=>updateUsersData(1, record.id)} />
          <ReactTooltip place="top" effect="solid">
          {loading ? "Wait for the previous task!" : "Remove registration"}
          </ReactTooltip>
          </>
        ) : type === "registered" ? (
          <>
            <ArrowUpwardIcon fontSize="small" className={loading ? "disabledIcon" : "confirmIcon"} data-tip onClick={()=>updateUsersData(6, record.id)} />
            <ReactTooltip place="top" effect="solid">
            {loading ? "Wait for the previous task!" : "Add admin privileges"}
            </ReactTooltip>
            <RemoveCircleOutlineIcon fontSize="small" className={loading ? "disabledIcon" : "deleteIcon"} data-tip onClick={()=>updateUsersData(3, record.id)} />
            <ReactTooltip place="top" effect="solid">
            {loading ? "Wait for the previous task!" : "Block user"}
            </ReactTooltip>
          </>
        ) : type === "blocked" ? (
          <>
          <CheckCircleOutlineIcon fontSize="small" className={loading ? "disabledIcon" : "confirmIcon"} onClick={()=>updateUsersData(4, record.id)} />
            <ReactTooltip place="top" effect="solid">
            {loading ? "Wait for the previous task!" : "Allow user"}
            </ReactTooltip>
            <DeleteOutlineIcon fontSize="small" className={loading ? "disabledIcon" : "deleteIcon"} onClick={()=>updateUsersData(5, record.id)} />
            <ReactTooltip place="top" effect="solid">
            {loading ? "Wait for the previous task!" : "Remove user permanently"}
            </ReactTooltip>
          </>
        ) : ""
      )}
    }]
  }

  const tableColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 71,
    },
    {
      field: 'email',
      headerName: 'User',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.7,
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1,
    },
    {
      field: 'last_visit',
      headerName: 'Last Visit [CET]',
      width: 160,
    },
    {
      field: 'created',
      headerName: 'Created [CET]',
      width: 160,
    },
  ]


  return (
    <div className={"user-manager"}>
      <h4>User Manager</h4>
      {
        usersData === null ? (
           <div className="main_loading"><Spinner animation="border" variant="primary" /><br/>Loading...</div>
        ) : (
          !Array.isArray(usersData.adminUsers) || usersData === 0 ? (
            <div className="main_loading"><Alert variant="danger">Sorry, something went wrong.</Alert></div>
          ) : (
            [...usersData.adminUsers, ...usersData.blockedUsers, ...usersData.registeredUsers, ...usersData.newUsers].length === 0 ? (
              <div className="main_loading"><Alert variant="info">No data.</Alert></div>
            ) : (
              <>
                <Accordion >
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      New registrations&nbsp;&nbsp;<Badge variant={usersData.newUsers.length ? "primary" : "secondary"}>{usersData.newUsers.length}</Badge>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <DataGrid
                          disableColumnMenu
                          autoHeight
                          size="small"
                          rows={usersData.newUsers}
                          columns={[...tableColumns, ...actionColumns("new")]}
                          rowHeight={38}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      Admins&nbsp;&nbsp;<Badge variant={usersData.adminUsers.length ? "primary" : "secondary"}>{usersData.adminUsers.length}</Badge>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <DataGrid
                          disableColumnMenu
                          autoHeight
                          size="small"
                          rows={usersData.adminUsers}
                          columns={[...tableColumns, ...actionColumns("admins")]}
                          rowHeight={38}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      Registered&nbsp;&nbsp;<Badge variant={usersData.registeredUsers.length ? "primary" : "secondary"}>{usersData.registeredUsers.length}</Badge>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <DataGrid
                          autoHeight
                          disableColumnMenu
                          size="small"
                          rows={usersData.registeredUsers}
                          columns={[...tableColumns, ...actionColumns("registered")]}
                          rowHeight={38}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                      Blocked&nbsp;&nbsp;<Badge variant={usersData.blockedUsers.length ? "primary" : "secondary"}>{usersData.blockedUsers.length}</Badge>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <DataGrid
                          autoHeight
                          disableColumnMenu
                          size="small"
                          rows={usersData.blockedUsers}
                          columns={[...tableColumns, ...actionColumns("blocked")]}
                          rowHeight={38}
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </>
            )
          )
        )
      }
    </div>
  )
}

export default UserManager

