import React from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import Training from './Training'

const TrainingsList = styled.div`
  padding: 5px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : '#ececec'};
  flex-grow: 1;
  min-height: 117px;
  //border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 0px 5px 15px 0px;
`

class ListOfTrainings extends React.Component {

  render(){

    const printDroppable = (column) => {
      return (
        <Droppable
          droppableId={column.id}
          isDropDisabled={false}
        >
          {(provided, snapshot) => (
            <TrainingsList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {column.trainingIds.map((training, index) => {
                return <Training key={this.props.trainings[training].id} training={this.props.trainings[training]} index={index} removeTraining={this.props.removeTraining} openAddModal={this.props.openAddModal}   columnId={column.id}/>
              })}
              {provided.placeholder}
            </TrainingsList>
          )}
        </Droppable>
      )
    }

    return (
      <Col xs lg="6" xl="4" className="list-of-courses">
        <h3 className="section-title"> Available Trainings</h3>
        <div className="content">
          <div className="content-scroll">

            <div className="buttons-container">
              <Button variant="outline-primary" onClick={()=> this.props.openAddModal(null)}>Add training</Button>
              <Button variant="outline-primary" onClick={()=> this.props.openBrowseModal(null)}>Browse available trainings</Button>
              <Button variant="outline-secondary" onClick={this.props.fetchData} disabled={this.props.sampleTrainingsLoaded}>{this.props.sampleTrainingsLoaded ? 'Sample trainings loaded' : 'Load sample trainings'}</Button>
            </div>

            <Container >
              <Row>
                <Col>
                  {printDroppable(this.props.columns['available'])}
                </Col>
              </Row>
            </Container>

          </div>
        </div>

      </Col>
    )
  }
}

export default ListOfTrainings
