const ecsfSkills = [
  "Abilities to carry out working-life practices of the data protection and privacy issues involved in the implementation of the organisational processes, finance and business strategy",
  "Ability to explain and communicate data protection and privacy topics to stakeholders and users",
  "Ability to lead multidisciplinary cybersecurity teams",
  "Adapt and customise penetration testing tools and techniques",
  "Analyse and consolidate organisation’s quality and risk management practices",
  "Analyse and deliver cybersecurity education and training",
  "Analyse and implement cybersecurity standards, frameworks, policies, regulations, legislations, certifications and best practices",
  "Analyse and solve complex problems and security challenges",
  "Analyse business processes, assess and review software or hardware security, as well as technical and organisational controls",
  "Anticipate future cybersecurity threats, trends, needs and challenges in the organisation",
  "Anticipate required changes to the organisation’s information security strategy and formulate new plans",
  "Apply auditing tools and techniques",
  "Apply relevant standards, best practices and legal requirements for information security",
  "Assess and enhance an organisation’s cybersecurity posture",
  "Assess the security and performance of solutions",
  "Audit with integrity, being impartial and independent",
  "Automate threat intelligence management procedures",
  "Build a cybersecurity risk-aware environment",
  "Build resilience against points of failure across the architecture",
  "Collaborate with other team members",
  "Collect, evaluate, maintain and protect auditing information",
  "Command, communicate and report",
  "Communicate, coordinate and cooperate with internal and external stakeholders",
  "Communicate, explain and adapt legal and regulatory requirements and business needs",
  "Comprehensive understanding of the business strategy, models and products and ability to factor into legal, regulatory and standards’ requirements",
  "Conduct ethical hacking",
  "Conduct technical analysis and reporting",
  "Conduct user and business requirements analysis",
  "Conduct, monitor and review privacy impact assessments using standards, frameworks, acknowledged methodologies and tools",
  "Configure solutions according to the organisation’s security policy",
  "Continuously monitor new advancements and cybersecurity innovations",
  "Coordinate the integration of security solutions",
  "Decompose, analyse systems, spot weaknesses, develop security and privacy requirements and identify effective or ineffective related solutions",
  "Define and apply maturity models for cybersecurity management",
  "Design systems and architectures based on security and privacy by design and by defaults cybersecurity principles",
  "Design, apply, monitor and review Information Security Management System (ISMS) either directly or by leading its outsourcing",
  "Design, develop and deliver cybersecurity curricula and programmes to meet the organisation and individuals’ needs",
  "Develop advanced cybersecurity exercises and scenarios for simulations, virtual or cyber range environments",
  "Develop and communicate, detailed and reasoned investigation reports",
  "Develop and test secure code and scripts",
  "Develop codes, scripts and programmes",
  "Develop evaluation programs for the awareness, training and education activities",
  "Develop, champion and lead the execution of a cybersecurity strategy",
  "Document, report present and communicate with various stakeholders",
  "Draw architectural and functional specifications",
  "Enable business assets owners, executives and other stakeholders to make risk-informed decisions to manage and mitigate risks",
  "Enable employees to understand, embrace and follow the controls",
  "Establish a cybersecurity plan",
  "Explain and present digital evidence in a simple, straightforward and easy to understand way",
  "Follow and practice auditing frameworks, standards and methodologies",
  "Guide and communicate with implementers and IT/OT personnel",
  "Identify and exploit vulnerabilities",
  "Identify and select appropriate pedagogical approaches for the intended audience",
  "Identify and troubleshoot cybersecurity-related issues",
  "Identify needs in cybersecurity awareness, training and education",
  "Identify non-cyber events with implications on cyber-related activities",
  "Identify threat actors TTPs and campaigns",
  "Identify, analyse and correlate events",
  "Implement cybersecurity risk management frameworks, methodologies and guidelines and ensure compliance with regulations and standards",
  "Influence an organisation’s cybersecurity culture",
  "Integrate cybersecurity solutions to the organisation’s infrastructure",
  "Lead the development of appropriate cybersecurity and privacy policies and procedures that complement the business needs and legal requirements; further ensure its acceptance, comprehension and implementation and communicate it between the involved parties",
  "Manage and analyse log files",
  "Manage cybersecurity resources",
  "Model threats, actors and TTPs",
  "Motivate and incentivise learners",
  "Perform social engineering",
  "Practice all technical, functional and operational aspects of cybersecurity incident handling and response",
  "Practice ethical cybersecurity organisation requirements",
  "Propose and manage risk-sharing options",
  "Propose cybersecurity architectures based on stakeholder’s needs and budget",
  "Prove the soundness of the research results",
  "Provide practical solutions to cybersecurity issues",
  "Provide technological design leadership",
  "Provide training towards cybersecurity and data protection professional certifications",
  "Report, communicate and present to stakeholders",
  "Review and enhance security documents, reports, SLAs and ensure the security objectives",
  "Understand core organisational business processes",
  "Understand legal framework modifications implications to the organisation’s cybersecurity and data protection strategy and policies",
  "Understand, practice and adhere to ethical requirements and standards",
  "Use and apply CTI platforms and tools",
  "Use penetration testing tools effectively",
  "Work ethically and independently; not influenced and biased by internal or external actors",
  "Work in a team and cooperate with different external Subject Matter Experts whenever needed",
  "Work on operating systems, servers, clouds and relevant infrastructures",
  "Write and communicate intelligence reports to stakeholders"
]

export default ecsfSkills
