import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { Draggable } from 'react-beautiful-dnd'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import areas from './initStructure/SpartaAreas'
import topics from './initStructure/SpartaTopics'

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDragDisabled
      ? 'lightgrey'
      : props.isDragging
        ? 'lightgreen'
        : 'white'};
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`

class Course extends React.Component {

  render() {
    const course = this.props.course
    const isDragDisabled = course.id === 'task-1'
    const showTopics = course.topics.map(el => {
      return topics[el.topicId].name
    })
    const showAreas = [...new Set(course.topics.map(el => {
      return areas[topics[el.topicId].areaId].name
    }))]

    return (
      <Draggable
        draggableId={course.id}
        index={this.props.index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            isDragDisabled={isDragDisabled}
          >

            <div className="course">
              <div className="rightBtn" onClick={()=>this.props.removeCourse({
                id: course.id,
                type: course.type,
                semester: course.semester,
                position: this.props.columnId
              })}>
                <ReactTooltip id={"Tooltip-action-remove"} place="top" effect="solid">
                  {isNaN(this.props.columnId.charAt(0)) ? "Permanently remove" : "Move back to the course list"}
                </ReactTooltip>
                {isNaN(this.props.columnId.charAt(0)) ? <DeleteForeverIcon data-tip data-for={"Tooltip-action-remove"} color="action"/> : <ClearIcon data-tip data-for={"Tooltip-action-remove"} color="action"/>}
              </div>

              <div className="leftBtn" onClick={()=>this.props.openAddModal(course.id, this.props.columnId)}>
                <EditIcon data-tip data-for={"Tooltip-action-edit"} color="action"/>
                <ReactTooltip id={"Tooltip-action-edit"} place="top" effect="solid">
                  Edit
                </ReactTooltip>
              </div>

              <div className="semester">{course.semester} {course.type} course
              </div>
      		    <div className="title">{course.name}</div>
              <div className="area_topics">
                {showAreas.map((el, index) => (
                  <font key={index}>{el}{index !== (showAreas.length -1) ? " / " : ""}</font>
                ))}
                <table><tbody>
                  {showTopics.map((el, index) => (
                    <tr key={index}><td>•&nbsp;</td><td>{el}</td></tr>
                  ))}
                </tbody></table>
              </div>
            {/*<div className="credits">ECTS {course.credits}</div>*/}
            </div>
          </Container>
        )}
      </Draggable>
    )
  }
}

export default Course
