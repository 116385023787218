import React from 'react'
import { Col, Badge, Button, Form, Row } from 'react-bootstrap'
import { Config } from '../../config'
import Axios from 'axios'
import ReactTooltip from 'react-tooltip'
import SkillSelectionModal from '../admin/SkillSelectionModal'
import { euStates, euLanguages } from '../utils'

class TrainingForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          formData: this.initForm(props.formData),
          isLoading: false,
          formValidated: false,
          deleteModal: null,
          showSkillsModal: false
        }
      }
    
    // if no data passed by parent init empty form
    initForm = (formData) => {
        const emptyForm = {
            name : "",
            organizer: "",
            description : "",
            link : "",
            language : [],
            duration : "",
            type_format : "",
            country: "",
            timing : "",
            dates : [],
            content_type : "",
            prerequisites : "",
            can_lead_to_certification: "",
            includes_exams_for_certification : "",
            price : "",
            skills_group: [],
            specific_skills: [],
            specific_knowledge: []
          }

        if (formData === null) {
            return emptyForm
        } else {
            return formData
        }
    }

    // save form data of new or edited table row
    saveForm = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.setState({formValidated: true})

        const form = event.currentTarget
        if (form.checkValidity() === false) {
        return
        }
        this.setState({isLoading: true, formValidated: false, })

        if (typeof this.props.saveLocally === 'function') {
            // only return data locally to parent
            this.props.saveLocally(this.state.formData)
            this.props.hideForm()
        } else {
            // prepare data to send to db
            let values = JSON.parse(JSON.stringify(this.state.formData))
            delete values.created
            delete values.created_by
            delete values.updated
            delete values.updated_by
            values.dates = JSON.stringify(values.dates)
            values.language = JSON.stringify(values.language)
            values.skills_group = JSON.stringify(values.skills_group)
            values.specific_skills = JSON.stringify(values.specific_skills)
            values.specific_knowledge = JSON.stringify(values.specific_knowledge)

            Axios.post( this.state.formData.hasOwnProperty('id') ? Config.server.editTraining : Config.server.addTraining, {...this.props.authData, ...values}, {headers: { 'Content-Type': 'application/json' }})
            .then((response) => {
                let responseData = response.data
                if (!responseData.error) {
                //this.setState({isLoading: false, formData: null})
                this.props.showMessage({type: 'success', content: "Data updated successfully."})
                this.props.getPublicData()
                this.props.hideForm()
                } else {
                this.setState({isLoading: false})
                this.props.showMessage({type: 'error', content: "Something went wrong!"})
                console.log(responseData.message)
                }
            })
            .catch((error) => {
                console.log(error)
                this.setState({isLoading: false})
                this.props.showMessage({type: 'error', content: "Unable to connect to database."})
            })
        }
        
    }

    // add new item to array field of the form
    addItemTo = (variable) => {
        let newData = JSON.parse(JSON.stringify(this.state.formData))
        newData[variable].push("")
        this.setState({formData: newData})
    }

    // delete item from array field of the form
    deleteItemFrom = (variable, id) => {
        let newData = JSON.parse(JSON.stringify(this.state.formData))
        newData[variable].splice(id,1)
        this.setState({formData: newData})
    }

    // cancel and hide form
    cancelUpload = () => {
        //this.setState({formData: null, validated: false})
        this.props.hideForm()
    }

    // catch form change event and save new data
    changeFormData = (event, arrayId) => {
        //copy state formdata object to new temporary variable
        let newData = JSON.parse(JSON.stringify(this.state.formData))
        if (event.target.id.includes("language")) {
        // save as array
        newData.language[arrayId] = event.target.value
        } else if (event.target.id.includes("date")) {
        // save as array
        newData.dates[arrayId] = event.target.value
        } else {
        // save as string
        newData[event.target.id] = event.target.value
        }
        // store updated state object to state
        this.setState({formData: newData})
    }

    render(){
        const { userDataMerged, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, saveLocally } = this.props
        const { formValidated, formData, showSkillsModal } = this.state

        // show user name who edited the data row in the table; hide if unknown or error
        const userLabel = (userId) => {
            if ( Array.isArray(userDataMerged) && userDataMerged.length ){
            let userName = userDataMerged.find(x => x.id === userId).name
            return userName ? " by " + userName : ""
            } else {
            return ""
            }
        }

        // helper for display selected skills/knowledge by each skill group
        const selectedSkillsList = (category, groupId) => {
            let output = []
            if (category === "skills") {
            rsSkillsGroup.find(i => i.id === groupId).specific_skills.forEach((skillId, index) => {
                if (formData.specific_skills.includes(skillId)) {
                output.push(<li key={index}>{rsSpecificSkills.find(x => x.id === skillId).name}</li>)
                }
            })
            } else {
            rsSkillsGroup.find(i => i.id === groupId).specific_knowledge.forEach((knowledgeId, index) => {
                if (formData.specific_knowledge.includes(knowledgeId)) {
                output.push(<li key={index}>{rsSpecificKnowledge.find(x => x.id === knowledgeId).name}</li>)
                }
            })
            }
            return output.length ? <ul>{output}</ul> : <div className="empty">none</div>
        }

    return(
        <div className="training_form">
        
        {typeof saveLocally !== 'function' ? (<h4>{formData.hasOwnProperty("created") ? "Edit training" : "Add new training"}</h4>) : null}

        <Form noValidate validated={formValidated} onSubmit={this.saveForm} >

            <Form.Group as={Row} controlId="name">
                <Form.Label column sm={4}>Training name *</Form.Label>
                <Col sm={8}>
                <Form.Control required value={formData.name} onChange={this.changeFormData}/>
                <Form.Control.Feedback type="invalid">
                    Please provide a valid name of the training.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="organizer">
                <Form.Label column sm={4}>Organizer *</Form.Label>
                <Col sm={8}>
                <Form.Control required value={formData.organizer} onChange={this.changeFormData}/>
                <Form.Control.Feedback type="invalid">
                    Please provide a valid name of the organizer.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="description">
                <Form.Label column sm={4}>Description *</Form.Label>
                <Col sm={8}>
                <Form.Control required value={formData.description} onChange={this.changeFormData}/>
                <Form.Control.Feedback type="invalid">
                    Please provide a description.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="country">
                <Form.Label column sm={4}>Country *</Form.Label>
                <Col sm={8}>
                <Form.Control required as="select" value={formData.country} onChange={this.changeFormData}>
                    <option></option>
                    { euStates.map((item) => {
                        return (
                            <option key={item}>{ item }</option>
                        )
                    })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please choose a country.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="duration">
                <Form.Label column sm={4}>Duration (hours) *</Form.Label>
                <Col sm={8}>
                <Form.Control type="number" isInvalid={formData.duration !=='' && formData.duration < 1} required value={formData.duration} onChange={this.changeFormData}/>
                <Form.Control.Feedback type="invalid">
                    Please choose a duration.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId={"type_format"}>
                <Form.Label column sm={4}>Format *</Form.Label>
                <Col sm={8}>
                <Form.Control as="select" required value={formData.type_format} onChange={this.changeFormData}>
                    <option></option>
                    <option>Face-to-face</option>
                    <option>Online</option>
                    <option>Hybrid</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please choose a format.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId={"timing"}>
                <Form.Label column sm={4}>Timing *</Form.Label>
                <Col sm={8}>
                <Form.Control as="select" required value={formData.timing} onChange={this.changeFormData}>
                    <option></option>
                    <option>Fixed dates</option>
                    <option>Available online</option>
                    <option>On demand</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please choose a timing.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            {formData.dates.map((item, indx) => {
                return (
                <Form.Group as={Row} key={indx}>
                    <Form.Label column sm={4} >{indx === 0 ? (formData.timing === 'Fixed dates' ? 'Dates *' : 'Dates') : ""}</Form.Label>
                    <Col sm={5}>
                    <Form.Control
                        type="date"
                        required
                        id={"date-"+(indx+1)} 
                        key={indx} 
                        value={item} 
                        onChange={(e)=>this.changeFormData(e, indx)}/>
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid date or delete this row.
                    </Form.Control.Feedback>
                    </Col>
                    <Col sm={3} className="row-delete-button">
                    <Button variant="outline-danger" size="sm" onClick={()=>this.deleteItemFrom('dates', indx)}>Delete date</Button>
                    </Col>
                </Form.Group>
                )
            })}
            
            <Form.Group as={Row}>
                <Form.Label column sm={4} >{formData.dates.length === 0 ? (formData.timing === 'Fixed dates' ? 'Dates *' : 'Dates') : ""}</Form.Label>
                <Col sm={8} className="row-button">
                <Button variant="outline-success" size="sm" onClick={()=>this.addItemTo('dates')}>Add date</Button>
                <Form.Control style={{display: "none"}} required={formData.timing === 'Fixed dates'} value={formData.dates}/>
                <Form.Control.Feedback type="invalid">
                    Please add at least one start date. (Required when Timing is set to Fixed dates)
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId={"content_type"}>
                <Form.Label column sm={4}>Content type *</Form.Label>
                <Col sm={8}>
                <Form.Control as="select" required value={formData.content_type} onChange={this.changeFormData}>
                    <option></option>
                    <option>Theoretical only</option>
                    <option>Hands one</option>
                    <option>Theoretical and hands on</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please choose a content type.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="prerequisites">
                <Form.Label column sm={4}>Prerequisites</Form.Label>
                <Col sm={8}>
                <Form.Control value={formData.prerequisites} onChange={this.changeFormData}/>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId={"can_lead_to_certification"}>
                <Form.Label column sm={4}>Can lead to certification *</Form.Label>
                <Col sm={8}>
                <Form.Control as="select" required value={formData.can_lead_to_certification} onChange={this.changeFormData}>
                    <option></option>
                    <option>Yes (based on specific standards, recognized by national/international organizations)</option>
                    <option>No</option>
                    <option>Other (eg. certificate of attendance / participation)</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please choose an option.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId={"includes_exams_for_certification"}>
                <Form.Label column sm={4}>Includes exams for certification *</Form.Label>
                <Col sm={8}>
                <Form.Control as="select" required value={formData.includes_exams_for_certification} onChange={this.changeFormData}>
                    <option></option>
                    <option>Yes</option>
                    <option>No</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please choose an option.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            {formData.language.map((item, indx) => {
                return (
                <Form.Group as={Row} key={indx}>
                    <Form.Label column sm={4} >{indx === 0 ? "Language *" : ""}</Form.Label>
                    <Col sm={5}>
                    <Form.Control as="select" id={"language-"+(indx+1)} key={indx} value={item} required onChange={(e)=>this.changeFormData(e, indx)}>
                    <option></option>
                    { euLanguages.map((item) => {
                        return (
                            <option key={item}>{ item }</option>
                        )
                    })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                    Please provide a valid language or delete this row.
                    </Form.Control.Feedback>
                    </Col>
                    <Col sm={3} className="row-delete-button">
                    <Button variant="outline-danger" size="sm" onClick={()=>this.deleteItemFrom('language', indx)}>Delete language</Button>
                    </Col>
                </Form.Group>
                )
            })}

            <Form.Group as={Row}>
                <Form.Label column sm={4} >{formData.language.length === 0 ? "Language *" : ""}</Form.Label>
                <Col sm={8} className="row-button">
                <Button variant="outline-success" size="sm" onClick={()=>this.addItemTo('language')}>Add language</Button>
                <Form.Control style={{display: "none"}} required value={formData.language}/>
                <Form.Control.Feedback type="invalid">
                    Please add at least one language.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId={"price"}>
                <Form.Label column sm={4}>Price (excl. VAT) *</Form.Label>
                <Col sm={4}>
                <Form.Control type="number" isInvalid={formData.price !=='' && formData.price < 0} required value={formData.price} onChange={this.changeFormData}/>
                <Form.Control.Feedback type="invalid">
                    Please provide valid price (could be 0 if free).
                </Form.Control.Feedback>
                </Col>
                <Form.Label column sm={4}>€</Form.Label>
            </Form.Group>

            {formData.skills_group.length ? (
                <Form.Group as={Row} >
                <Form.Label column sm={4}>{formData.skills_group.length ? "REWIRE Skills" : ""}</Form.Label>
                <Col sm={8} >
                    <div className="skills-list">
                    {formData.skills_group.sort((a, b) => rsSkillsGroup.find(i => i.id === a).name.localeCompare(rsSkillsGroup.find(i => i.id === b).name)).map((groupId) => {
                    let color = rsSkillsGroup.find(i => i.id === groupId).color
                    return (
                        <span key={groupId}>
                        <Badge data-tip data-for={"Tooltip-skillgroup-" + groupId} style={{backgroundColor: color === "transparent" ? "#fafafa" : color}}
                        >
                            {rsSkillsGroup.find(i => i.id === groupId).name}
                        </Badge>
                        <ReactTooltip className="tooltip" id={"Tooltip-skillgroup-" + groupId} place="top" effect="solid">
                            <h5>{rsSkillsGroup.find(i => i.id === groupId).name}</h5>
                            <h6>Included skills:</h6>
                            {selectedSkillsList("skills", groupId)}
                            <h6>Included knowledge:</h6>
                            {selectedSkillsList("knowledge", groupId)}
                        </ReactTooltip>
                        </span>
                    )
                    })}
                    </div>
                </Col>
                </Form.Group>
            ) : null}

            <Form.Group as={Row}>
                <Form.Label column sm={4}>{formData.skills_group.length ? "" : "REWIRE Skills"}</Form.Label>
                <Col sm={8} className="row-button">
                <Button variant="outline-success" size="sm" onClick={()=>this.setState({showSkillsModal: true})}>Choose</Button>
                </Col>
            </Form.Group>

            <Form.Group as={Row} controlId={"link"}>
                <Form.Label column sm={4}>Link *</Form.Label>
                <Col sm={8}>
                <Form.Control required value={formData.link} onChange={this.changeFormData}/>
                <Form.Control.Feedback type="invalid">
                    Please provide a link.
                </Form.Control.Feedback>
                </Col>
            </Form.Group>

            {formData.hasOwnProperty("created") ? (<>
                <Form.Group as={Row} >
                <Form.Label column sm={4}>Created</Form.Label>
                <Form.Label column sm={8} id="created">{formData.created + " CET" + userLabel(formData.created_by)}</Form.Label>
                </Form.Group>

                <Form.Group as={Row} >
                <Form.Label column sm={4}>Updated</Form.Label>
                <Form.Label column sm={8} id="updated">{formData.updated + " CET" + userLabel(formData.updated_by)}</Form.Label>
                </Form.Group>
            </>) : ""}

            <div className="upload_buttons">
                <Button variant="danger" onClick={this.cancelUpload}>Cancel</Button>
                <Button type="submit" variant="success" >Confirm</Button>
            </div>

            </Form>

            <SkillSelectionModal rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} showSkillsModal={showSkillsModal} hideSkillModal={()=>this.setState({showSkillsModal: false})} formData={formData} saveFormData={(newData)=>this.setState({formData: {...formData, ...newData}})}/>
            </div>
    )
  }
}

export default TrainingForm