import React from 'react'
import { Nav } from 'react-bootstrap'
import {Config} from '../../config.js'
import Axios from 'axios'

import UserManager from './UserManager'
import StudyPrograms from './StudyPrograms'
import Certifications from './Certifications.js'
import Trainings from './Trainings.js'
import RewireFramework from './RewireFramework.js'


class Admin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      section: "",
      usersData: null
    }
  }

  componentDidMount() {
    this.getUsersData()
  }

  getUsersData = () => {
    Axios.post( Config.server.getUsersData, this.props.authData, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      if (response && response.data.error === false){
        this.setState({usersData: {
          newUsers: response.data.data.new_users.map(obj=> ({ ...obj, key: obj.id})),
          blockedUsers: response.data.data.blocked_users.map(obj=> ({ ...obj, key: obj.id})),
          adminUsers: response.data.data.admin_users.map(obj=> ({ ...obj, key: obj.id})),
          registeredUsers: response.data.data.registered_users.map(obj=> ({ ...obj, key: obj.id}))
        }})
      } else {
        console.log(response.data.message)
        this.setState({usersData: 0})
      }
    })
    .catch((error) => {
      this.setState({usersData: 0})
      console.log("Server is unavailable")
      console.log(error)
    })
  }

  render(){
    const { section, usersData } = this.state
    const { authData, showMessage, getPublicData, dbCertifications, dbStudyPrograms, dbTrainings, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills } = this.props

    const userDataMerged = usersData === null ? null : (
      usersData === 0 ? 0 : (
        Array.isArray(usersData.newUsers) && Array.isArray(usersData.blockedUsers) && Array.isArray(usersData.registeredUsers) && Array.isArray(usersData.adminUsers) && usersData.adminUsers.length ? (
          [...usersData.newUsers, ...usersData.blockedUsers, ...usersData.adminUsers, ...usersData.registeredUsers]
        ) : ( 0 )
      )
    )

    // usersData === 0 ? : (
    //   Array.isArray(usersData.newUsers) && Array.isArray(usersData.blockedUsers) && Array.isArray(usersData.registeredUsers) && Array.isArray(usersData.adminUsers) && usersData.adminUsers.length ? (
    //     [...usersData.newUsers, ...usersData.blockedUsers, ...usersData.adminUsers, ...usersData.registeredUsers]
    //   ) : ( 0 )
    // )


    return (

        <div className="admin_main">
          <Nav id="admin_nav" className="justify-content-center" variant="pills" defaultActiveKey={section}>
            <Nav.Item>
              <Nav.Link eventKey="nav-1" onClick={()=>this.setState({section: "nav-1"})}>Certifications</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="nav-2" onClick={()=>this.setState({section: "nav-2"})}>Study Programs</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="nav-3" onClick={()=>this.setState({section: "nav-3"})}>Trainings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="nav-5" onClick={()=>this.setState({section: "nav-5"})}>Skill Framework</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="nav-4" onClick={()=>this.setState({section: "nav-4"})}>Users</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="admin_container">
          {
            this.state.section === "nav-1" ? (
              <Certifications authData={authData} userDataMerged={userDataMerged} showMessage={showMessage} getPublicData={getPublicData} dbCertifications={dbCertifications} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills}/>
            ) : (
              this.state.section === "nav-2" ? (
                <StudyPrograms authData={authData} userDataMerged={userDataMerged} showMessage={showMessage} getPublicData={getPublicData} dbStudyPrograms={dbStudyPrograms} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} />
              ) : (
                this.state.section === "nav-3" ? (
                  <Trainings authData={authData} userDataMerged={userDataMerged} showMessage={showMessage} getPublicData={getPublicData} dbTrainings={dbTrainings} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills}/>
                ) : (
                  this.state.section === "nav-4" ? (
                    <UserManager authData={authData} showMessage={showMessage} usersData={usersData} getUsersData={this.getUsersData}/>
                  ) : (
                    this.state.section === "nav-5" ? (
                      <RewireFramework authData={authData} showMessage={showMessage} getPublicData={getPublicData} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills}/>
                    ) : (
                      <>
                        <h4>Admin Panel</h4><h6>Please, choose a section</h6>
                      </>
                    )
                  )
                )
              )
            )
          }
          </div>

        </div>
    )
  }
}

export default Admin
