import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Button, Nav, Navbar, NavDropdown, OverlayTrigger, Popover } from 'react-bootstrap'
import Axios from 'axios'
import { createHash } from 'crypto'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import {Config} from '../config.js'
import VisitorStats from './/VisitorStats'
import NotFound from './/NotFound'
import Curricula from './curricula/Curricula.js'
import Trainings from './trainings/Trainings.js'
import Certifications from './certifications/Certifications.js'
import Statistics from './statistics/Statistics.js'
// import FeedbackModal from './FeedbackModal.js'
import EditUserModal from './userModals/EditUserModal'
import ForgotPassModal from './userModals/ForgotPassModal'
import LoginModal from './userModals/LoginModal'
import RegisterModal from './userModals/RegisterModal'
import Admin from './admin/Admin'

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      // error: null,
      section: "",
      userModal: "",
      user: null,
      messageInfo: {show: false, content: '', delay: 3000, type: 'info', key: 'init'},

      dbCertifications: null,
      dbStudyPrograms: null,
      dbTrainings: null,
      rsSkillsGroup: null,
      rsSpecificKnowledge: null,
      rsSpecificSkills: null
      /*
      stavy dat:
        init loading = null
        chyba pri loadingu = 0
        prazdne data = []
        plne data = [data]
      */
    }
  }

  componentDidMount() {
    this.getAllData()
  }

  getAllData = () => {
    this.setState({
      dbCertifications: null,
      dbStudyPrograms: null,
      dbTrainings: null,
      rsSkillsGroup: null,
      rsSpecificKnowledge: null,
      rsSpecificSkills: null
    })
    Axios.get( Config.server.getAllData)
      .then((response) => {
        if (response && response.data.error === false){
          this.setState({
            dbCertifications: response.data.data.certifications,
            dbStudyPrograms: response.data.data.study_programs,
            dbTrainings: response.data.data.trainings,
            rsSkillsGroup: response.data.data.rs_skill_group,
            rsSpecificKnowledge: response.data.data.rs_specific_knowledge,
            rsSpecificSkills: response.data.data.rs_specific_skills
          })
        } else {
          console.log(response.data.message)
          this.setState({
            dbCertifications: 0,
            dbStudyPrograms: 0,
            dbTrainings: 0,
            rsSkillsGroup: 0,
            rsSpecificKnowledge: 0,
            rsSpecificSkills: 0
          })
        }
      })
      .catch((error) => {
        console.log("Server is unavailable")
        console.log(error)
        this.setState({
          dbCertifications: 0,
          dbStudyPrograms: 0,
          dbTrainings: 0,
          rsSkillsGroup: 0,
          rsSpecificKnowledge: 0,
          rsSpecificSkills: 0
        })
      })
  }

  changeSection = (newSection) => {
    this.setState({section: newSection})
  }

  login = (userData) => {
    this.setState({user: userData, userModal: ""})
  }

  logout = (authData) => {
    Axios.post( Config.server.logout, authData, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      // logged out even in case of error (unauthorized access)
      this.showMessage({type: 'success', content: "Now you are logged out."})
      this.setState({user: null, section: this.state.section === "admin" ? '' : this.state.section})
    })
    .catch((error) => {
      console.log(error)
      this.showMessage({type: 'error', content: "Something went wrong!"})
    })
  }

  setUser = (newUserData) => {
    this.setState({user: newUserData})
  }

  userModalSwitch = (operation) => {
    this.setState({userModal: operation})
  }

  showMessage = (messageData) => {
    this.setState({messageInfo: {
      ...messageData,
      show: true,
      key: new Date().getTime(),
      delay: messageData.delay ? messageData.delay : 3000
    }})
  }


  render(){
    const { section, user, dbCertifications, dbStudyPrograms, dbTrainings, rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills, messageInfo, userModal } = this.state

    const authData = user ? {auth_a: user.id, auth_b: createHash('sha256').update(user.id + user.email + user.password + user.token).digest('hex')} : {auth_a: "", auth_b: ""}

    const getInitials = () => {
      let array = (user.name).split(" ")
      let output = ""
      array.forEach((item) => {
        output += item.charAt(0)
      })
      return output
    }

    return (
      <Router>
        <div className="app-navbar">
          <Navbar bg="dark" variant="dark">
            <Navbar.Brand>{' '} Cyber Security Profiler</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link >Home</Nav.Link>
                <NavDropdown title="Menu" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={()=>this.changeSection("curricula")}>Curricula</NavDropdown.Item>
                  <NavDropdown.Item onClick={()=>this.changeSection("trainings")}>Training</NavDropdown.Item>
                  <NavDropdown.Item onClick={()=>this.changeSection("certifications")}>Certification</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={()=>this.changeSection("statistics")}>Statistics</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href={Config.appRoot + "files/DesignerHowTo.pdf"} target="_blank" rel="noopener noreferrer">Help</Nav.Link>
              </Nav>
              <Nav className="nav-user">
                {user ? (<>
                  {user.admin_level === 2 ? <Nav.Link onClick={()=>this.changeSection("admin")}>Admin</Nav.Link> : "" }
                  <OverlayTrigger
                    trigger={['click']}
                    rootClose={true}
                    key={"user-edit"}
                    placement={"bottom"}
                    overlay={
                      <Popover id={`popover-user-edit`}>
                        <Popover.Title as="h3"><PersonIcon/> Your profile</Popover.Title>
                        <Popover.Content>
                        <div className="profileInfo">
                          <table><tbody>
                            <tr><td>Name:</td><td>{user.name}</td></tr>
                            <tr><td>Organization:</td><td>{user.company}</td></tr>
                            <tr><td>E-mail:</td><td>{user.email}</td></tr>
                            <tr><td>Password:</td><td>****</td></tr>
                            <tr><td></td><td></td></tr>
                          </tbody></table>
                          <div><Button type="outlined" size="sm" onClick={()=>this.userModalSwitch("edit")}><EditOutlinedIcon fontSize="small" className="editIcon" /> Edit</Button></div>
                        </div>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <Avatar className="avatar logged">{getInitials() ? getInitials() : <PersonIcon/>}</Avatar>
                  </OverlayTrigger>
                  <Button variant="outline-light" onClick={()=>this.logout(authData)}>Log Out</Button>
                </>) : (<>
                  <Avatar className="avatar" onClick={()=>this.userModalSwitch("login")}><PersonIcon/></Avatar>
                  <Button variant="outline-light" onClick={()=>this.userModalSwitch("login")}>Log In</Button>
                </>)}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="app">
          <Switch>
            <Route exact path={Config.appRoot} render={()=>
              <div>
                {/*<div className="main">
                {error ? (
                  <div className="main_loading">
                    <Alert variant="danger">{error.message}</Alert>
                    <div><Link to={"/"} onClick={()=>{window.location.reload()}}>Go back</Link></div>
                  </div>
                ) : (
                */
                  section === "trainings" ? (
                    <Trainings dbTrainings={dbTrainings} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills}/>
                  ) : section === "certifications" ? (
                    <Certifications dbCertifications={dbCertifications} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills}/>
                  ) : section === "statistics" ? (
                    <Statistics/>
                  ) : section === "admin" && user ? (
                    <Admin authData={authData} showMessage={this.showMessage} getPublicData={this.getAllData} dbCertifications={dbCertifications} dbStudyPrograms={dbStudyPrograms} dbTrainings={dbTrainings} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills} />
                  ) : (
                    <Curricula showMessage={this.showMessage} rsSkillsGroup={rsSkillsGroup} rsSpecificKnowledge={rsSpecificKnowledge} rsSpecificSkills={rsSpecificSkills}/>
                  )
              }</div>
            } />
            <Route path={Config.appRoot + "visitor-stats"} component={VisitorStats} />
            <Route component={NotFound} />
          </Switch>
        </div>
{/*
          <footer>
            <p>This project has received funding from the European Union's Horizon 2020 research and innovation programme under grant agreement No 830892.</p>
          </footer>
*/}

{/* --- BEGIN MODALS --- */}

        <LoginModal visible={userModal === "login"} switchLogin={this.userModalSwitch} setUser={this.login} showMessage={this.showMessage} onCancel={()=>this.userModalSwitch("")} />

        <RegisterModal visible={userModal === "register"} switchLogin={this.userModalSwitch} showMessage={this.showMessage} onCancel={()=>this.userModalSwitch("")} />

        <ForgotPassModal visible={userModal === "forgot"} switchLogin={this.userModalSwitch} showMessage={this.showMessage} onCancel={()=>this.userModalSwitch("")} />

        <EditUserModal user={user} visible={userModal === "edit"} setUser={this.setUser} switchLogin={this.userModalSwitch} showMessage={this.showMessage} onCancel={()=>this.userModalSwitch("")} authData={authData} />

      {  /* zprovoznime pozdejc
          <FeedbackModal/>
        */}

{/* --- END MODALS --- */}

        {/* message */}
        <Snackbar open={messageInfo.show} autoHideDuration={messageInfo.delay} key={messageInfo.key} onClose={()=>{this.setState({messageInfo: {...messageInfo, show: false}})}} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
          <MuiAlert elevation={6} onClose={()=>{this.setState({messageInfo: {...messageInfo, show: false}})}} severity={messageInfo.type}>
            {messageInfo.content}
          </MuiAlert>
        </Snackbar>

      </Router>

    )
  }

}

export default App;
