const ecsfKnowledge = [
  "Advanced knowledge of auditing frameworks, standards, methodologies and certifications",
  "Advanced knowledge of CTI sharing standards",
  "Advanced knowledge of cybersecurity attack vectors",
  "Advanced knowledge of cybersecurity awareness, education and training programme development",
  "Advanced knowledge of cybersecurity solutions",
  "Advanced knowledge of data privacy and protection laws and regulations",
  "Advanced knowledge of IT/OT appliances, operating systems and computer networks",
  "Advanced knowledge of IT/OT, operating systems and computer networks",
  "Advanced knowledge of National, EU and international cybersecurity and related privacy standards, legislation, policies and regulations",
  "Advanced knowledge of penetration testing tools, techniques and methodologies",
  "Advanced knowledge of risk management frameworks, standards, methodologies, tools, guidelines and best practices",
  "Basic understanding of data storage, processing and protections within systems, services and infrastructures",
  "Knowledge of advanced and persistent cyber threats and threat actors",
  "Knowledge of best practices on cybersecurity",
  "Knowledge of big data handling and analytics methods",
  "Knowledge of computer networks security",
  "Knowledge of conformity assessment methodologies",
  "Knowledge of criminal investigation methodologies and procedures",
  "Knowledge of cutting-edge methods, tools and techniques on hands-on cybersecurity education and training",
  "Knowledge of cyber threats, threats taxonomies and vulnerabilities repositories",
  "Knowledge of cybersecurity and privacy standards, frameworks, policies, regulations, legislations, certifications and best practices",
  "Knowledge of cybersecurity attacks tactics and techniques",
  "Knowledge of cybersecurity frameworks, methodologies, controls and best practices",
  "Knowledge of cybersecurity incident handling methodologies",
  "Knowledge of cybersecurity incident handling practices and tools",
  "Knowledge of cybersecurity maturity models",
  "Knowledge of cybersecurity methods, methodologies, tools and techniques",
  "Knowledge of cybersecurity risks and threats",
  "Knowledge of cybersecurity solutions, technical and organisational controls",
  "Knowledge of cybersecurity tactics, techniques and procedures",
  "Knowledge of cybersecurity-related legal framework, regulations, standards",
  "Knowledge of cybersecurity-related professional certifications",
  "Knowledge of cybersecurity-related technologies and controls",
  "Knowledge of digital forensics analysis techniques",
  "Knowledge of digital forensics methods, best practices and tools",
  "Knowledge of digital forensics testing techniques",
  "Knowledge of incident handling communication cycle",
  "Knowledge of information security",
  "Knowledge of legacy security techniques",
  "Knowledge of legal compliance requirements and practices",
  "Knowledge of legal framework related to cybersecurity and data protection",
  "Knowledge of malware analysis tools",
  "Knowledge of management practices",
  "Knowledge of monitoring, implementing, testing and evaluating the effectiveness of the controls",
  "Knowledge of offensive and defensive security practices",
  "Knowledge of operating systems internals, networking protocols and services",
  "Knowledge of operating systems security",
  "Knowledge of pedagogical methods",
  "Knowledge of privacy impact assessment methodologies",
  "Knowledge of privacy-by-design methodologies",
  "Knowledge of Privacy-Enhancing Technologies (PET)",
  "Knowledge of programming languages",
  "Knowledge of programs and grants",
  "Knowledge of project management and budgeting",
  "Knowledge of recent vulnerability disclosures, data breach incidents and geopolitical events impacting cyber risk",
  "Knowledge of research, development and innovation (RDI) relevant to cybersecurity subject matters",
  "Knowledge of resource management",
  "Knowledge of risk sharing options and best practices",
  "Knowledge of scripting and programming languages",
  "Knowledge of secure coding practices",
  "Knowledge of secure development lifecycle",
  "Knowledge of security architecture reference models and security solutions",
  "Knowledge of security controls",
  "Knowledge of security controls frameworks, standards",
  "Knowledge of security technologies and solutions",
  "Knowledge of security vulnerabilities",
  "Knowledge of statistics and forecasting methodologies",
  "Knowledge of systems development life cycle",
  "Knowledge of technical and organisational controls that appropriately mitigate cybersecurity risks",
  "Knowledge of test methodologies and practices",
  "Knowledge of the latest cybersecurity trends",
  "Knowledge of the operation of Secure Operation Centres (SOCs) and Computer Security Incident Response Teams (CSIRTs)",
  "Knowledge of TTP frameworks",
  "Understanding of copyright and intellectual property rights issues, standards and patent filing",
  "Understanding of cybersecurity-related standards and compliance requirements",
  "Understanding of espionage and coercion threats and risk in international research",
  "Understanding of ethical cybersecurity organisation requirements",
  "Understanding of organisation’s mission and business objectives risks",
  "Understanding of responsible disclosure of cybersecurity-related information",
  "Understanding of security-related standards and requirements",
  "Understanding of the multidiscipline aspect of cybersecurity"
]

export default ecsfKnowledge
