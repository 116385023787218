import React from 'react'
import {Config} from '../../config.js'
import Axios from 'axios'
import {createHash} from 'crypto'
import { Button, Form, Modal, Row, Spinner } from 'react-bootstrap'


const ForgotPassModal = ({ visible, switchLogin, setUser, onCancel, showMessage }) => {
  const [loading, setLoading] = React.useState(false)
  const [validated, setValidated] = React.useState(false)
  const [formData, setFormData] = React.useState({email: "", password: ""})

  function submit(event){
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    setValidated(false)
    setLoading(true)
    let values = JSON.parse(JSON.stringify(formData))
    values.password = createHash('sha256').update(values.password).digest('hex')

    Axios.post( Config.server.forgotPassword, values, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      setLoading(false)
      if (response && response.data.error === false){
        if (response.data.email.error === false){
          setFormData({email: ""})
          showMessage({type: 'success', content: "New password has been sent to your e-mail."})
          switchLogin('')
        } else {
          showMessage({type: 'error', content: "We are sorry. Something went wrong."})
        }
      } else {
        console.log("error: " + response.data.message)
        showMessage({type: 'error', content: response.data.message && response.data.message.includes("Wrong e-mail") ? response.data.message : "Something went wrong!"})
      }
    })
    .catch((error) => {
      setLoading(false)
      console.log(error)
      showMessage({type: 'error', content: "Something went wrong!"})
    })
  }

  function formChange(event) {
    setFormData({...formData, [event.target.id]: event.target.value})
  }


  return (
    <Modal
      show={visible}
      onHide={onCancel}
      backdrop="static"
      centered
      className="user-modal"
      id="forgot-user-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <br/>
        <Form noValidate validated={validated} onSubmit={submit}>

          <Form.Group as={Row} >
            <Form.Label column sm="12" className="left">
              Please enter your account e-mail (login).
            </Form.Label>
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Control type="email" placeholder="Enter e-mail" required value={formData.email} onChange={formChange}/>
            <Form.Control.Feedback type="invalid">Please enter a valid e-mail address.</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Row} >
            <Form.Label column sm="12" className="left">
              A new password will be sent to you.
            </Form.Label>
          </Form.Group>

          <br/>

          <Form.Group style={{textAlign: "center"}}>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <><Spinner as="span" animation="border" size="sm" role="status"/>&nbsp;&nbsp;Loading...</>
              ): ( "OK" )}
            </Button>
            &nbsp;&nbsp;
            <Button variant="outline-secondary" onClick={()=>switchLogin("login")}>
              Back
            </Button>
          </Form.Group>

        </Form>
      </Modal.Body>

    </Modal>
  )
}

export default ForgotPassModal
