
export const checkColumnStructure = (object1, object2, keyI) => {

  const isObject = (object) => {
    return object != null && typeof object === 'object'
  }

  const checkColumnStructureFunction = (o1,o2,keyI) =>{

    if (keyI === "courseIds") return true
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length || keys1.toString() !== keys2.toString()) {
      console.log(keyI)
      console.log(keys1)
      console.log(keys2)
      return false
    }

    for (const key of keys1) {
      const val1 = object1[key]
      const val2 = object2[key]
      const areObjects = isObject(val1) && isObject(val2)
      // if (areObjects && !deepEqual(val1, val2) || !areObjects && val1 !== val2) {
      if (areObjects && !checkColumnStructure(val1, val2, key)) {
        return false
      }
    }
    return true;
  }

  return checkColumnStructureFunction(object1, object2, keyI)
}


export const checkCourseStructure = (courses, columns) => {

  const arrayComparison = (arrA, arrB) => {
    // compare length and if all 1st array contains all 2nd array elements
    let test1 = arrA.every(a => arrB.some(b => a === b))
    let test2 = arrA.length === arrB.length
    return test1===true && test2===true
  }

  let inputCourses = [
    ...columns['custom-w'].courseIds,
    ...columns['custom-s'].courseIds,
    ...columns['mandatory-w'].courseIds,
    ...columns['mandatory-s'].courseIds,
    ...columns['voluntary-w'].courseIds,
    ...columns['voluntary-s'].courseIds,
    ...columns['1-w'].courseIds,
    ...columns['1-s'].courseIds,
    ...columns['2-w'].courseIds,
    ...columns['2-s'].courseIds,
    ...columns['3-w'].courseIds,
    ...columns['3-s'].courseIds
  ]

  // test 1 - columns contains all courses
  let output = arrayComparison(Object.keys(courses), inputCourses)

  // test 2 - valid courses structure
  let emptyCourse = {id: "", name: "", semester: "", training: "", type: "", credits: "", description: "", skills_group: [], specific_skills: [], specific_knowledge: [], topics: [{ectsPer: null, topicId: null}], ecsfKnowledgeIds: [], ecsfSkillIds: []}
  inputCourses.forEach((courseId) => {
     if (!arrayComparison(Object.keys(courses[courseId]),Object.keys(emptyCourse))) output = false
     courses[courseId].topics.forEach((topic)=>{
       if (!arrayComparison(Object.keys(topic),Object.keys(emptyCourse.topics[0]))) output = false
     })
  })

  return output
}
