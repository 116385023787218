const sampleCourses = [
    {
        "name": "Communication Technologies",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 3,
                "ectsPer": 0.5
            },
            {
                "topicId": 12,
                "ectsPer": 0.5
            }
        ],
        "ecsfSkillIds": [5, 21, 36, 37, 41, 52, 54, 65, 74],
        "ecsfKnowledgeIds": [3, 18, 22, 30, 31, 47],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 6,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Computers and Programming 1",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 0,
                "ectsPer": 0.5
            },
            {
                "topicId": 6,
                "ectsPer": 0.5
            }
        ],
        "ecsfSkillIds": [14, 19, 29, 39],
        "ecsfKnowledgeIds": [15, 44, 46, 51],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 5,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Computers and Programming 2",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 6,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [43, 53, 60 ],
        "ecsfKnowledgeIds": [59, 62, 67, 69],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 5,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Course 1",
        "semester": "winter",
        "type": "voluntary",
        "topics": [
            {
                "topicId": 27,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [2, 6, 9, 10, 12, 13, 22],
        "ecsfKnowledgeIds": [ 10, 20, 25],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 9,
        "description": "",
        "training": "No"
    },
    {
        "name": "Course 2",
        "semester": "winter",
        "type": "voluntary",
        "topics": [
            {
                "topicId": 28,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [4, 17, 21, 45, 46, 58, 69],
        "ecsfKnowledgeIds": [10, 19, 32, 43, 57, 68],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 9,
        "description": "",
        "training": "No"
    },
    {
        "name": "Course 3",
        "semester": "summer",
        "type": "voluntary",
        "topics": [
            {
                "topicId": 11,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [33, 35, 42, 47, 59],
        "ecsfKnowledgeIds": [29, 42, 56],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 5,
        "description": "",
        "training": "No"
    },
    {
        "name": "Course 4",
        "semester": "summer",
        "type": "voluntary",
        "topics": [
            {
                "topicId": 25,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [63, 68, 72, 76, 77],
        "ecsfKnowledgeIds": [62, 76],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 6,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Data Communication",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 2,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [18, 27, 31, 34, 44, 50, 70, 73, 75],
        "ecsfKnowledgeIds": [27, 38, 49, 50, 60, 61, 64, 70, 74, 77, 79],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 6,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Discrete Mathematics",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 16,
                "ectsPer": 0.5
            },
            {
                "topicId": 18,
                "ectsPer": 0.5
            }
        ],
        "ecsfSkillIds": [],
        "ecsfKnowledgeIds": [],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 6,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Foundations of Cryptography",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 9,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [64, 80, 83, 85],
        "ecsfKnowledgeIds": [54, 58, 66, 72],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 6,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Incidents",
        "credits": 6,
        "training": "Yes",
        "topics": [
            {
                "topicId": 15,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [21, 62, 67, 84],
        "ecsfKnowledgeIds": [19, 21, 23, 24, 36, 40, 45, 71],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "semester": "summer",
        "description": "",
        "type": "mandatory"
    },
    {
        "name": "Legal Theory",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 27,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [0, 1, 24, 28],
        "ecsfKnowledgeIds": [5, 8, 11, 37],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 7,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Mathematics 1",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 16,
                "ectsPer": 0.5
            },
            {
                "topicId": 20,
                "ectsPer": 0.5
            }
        ],
        "ecsfSkillIds": [],
        "ecsfKnowledgeIds": [],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 7,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Physics",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 21,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [30, 32, 71],
        "ecsfKnowledgeIds": [73, 75, 78, 80],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 6,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Multimedia Services",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 0,
                "ectsPer": 0.5
            },
            {
                "topicId": 12,
                "ectsPer": 0.5
            }
        ],
        "ecsfSkillIds": [16, 20, 26, 55, 56],
        "ecsfKnowledgeIds": [1, 4, 7, 12, 14],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 6,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Mathematics",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 20,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [7, 19, 21],
        "ecsfKnowledgeIds": [26, 52, 53, 55],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 6,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Privacy",
        "credits": 6,
        "training": "Yes",
        "topics": [
            {
                "topicId": 22,
                "ectsPer": 0.5
            },
            {
                "topicId": 24,
                "ectsPer": 0.5
            }
        ],
        "ecsfSkillIds": [61, 78, 79],
        "ecsfKnowledgeIds": [39, 48],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "semester": "winter",
        "description": "",
        "type": "mandatory"
    },
    {
        "name": "Probability and Statistics",
        "semester": "winter",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 19,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [20, 38, 48, 57, 82],
        "ecsfKnowledgeIds": [17, 19, 21, 33, 34, 35, 40, 41, 45],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 6,
        "description": "",
        "training": "Yes"
    },
    {
        "name": "Testing",
        "credits": 6,
        "training": "Yes",
        "topics": [
            {
                "topicId": 14,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [3, 21, 25, 40, 51, 66, 81],
        "ecsfKnowledgeIds": [2, 6, 9, 13, 58, 65],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "semester": "winter",
        "description": "",
        "type": "mandatory"
    },
    {
        "name": "Theoretical Informatics",
        "semester": "summer",
        "type": "mandatory",
        "topics": [
            {
                "topicId": 5,
                "ectsPer": 1
            }
        ],
        "ecsfSkillIds": [8, 11, 15, 20, 23, 49],
        "ecsfKnowledgeIds": [0, 16, 28, 63],
        "skills_group": [],
        "specific_skills": [],
        "specific_knowledge": [],
        "credits": 7,
        "description": "",
        "training": "Yes"
    }
]

export default sampleCourses
