import React from 'react'
import {Badge, Col, Tab, Tabs, Table} from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts'

import areas from './initStructure/SpartaAreas'
import topcs from './initStructure/SpartaTopics'
import comps from './initStructure/NiceCompetencies'
import roles from './initStructure/NistWorkRoles'
import ecsfProfiles from './initStructure/ecsfProfiles'
import ecsfSkills from './initStructure/ecsfSkills'
import ecsfKnowledge from './initStructure/ecsfKnowledge'


class Statistics extends React.Component {

  render(){

    const courses = () => {
      let tmp = []
      this.props.columns['1-w'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '1-w'}})))
      })
      this.props.columns['1-s'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '1-s'}})))
      })
      this.props.columns['2-w'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '2-w'}})))
      })
      this.props.columns['2-s'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '2-s'}})))
      })
      this.props.columns['3-w'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '3-w'}})))
      })
      this.props.columns['3-s'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '3-s'}})))
      })
      return tmp
    }

    const compIds = () => {
      let tmp = []
      courses().forEach(course => {
        course.topics.forEach(topic => {
          tmp = tmp.concat(topcs[topic.topicId].comps)
        })
      })
      return [...new Set(tmp)].sort((a,b) => {return a - b})
    }

    const ecsfSkillIds = () => {
      let tmp = []
      courses().forEach(course => {
        tmp = tmp.concat(course.ecsfSkillIds)
      })
      return [...new Set(tmp)].sort((a,b) => {return a - b})
    }

    const ecsfKnowledgeIds = () => {
      let tmp = []
      courses().forEach(course => {
        tmp = tmp.concat(course.ecsfKnowledgeIds)
      })
      return [...new Set(tmp)].sort((a,b) => {return a - b})
    }

    const ectsSum = () => {
      let ects = {'1-w': 0, '1-s': 0, '2-w': 0, '2-s': 0, '3-w': 0, '3-s': 0, 'sum': 0}
      courses().forEach((course, index) => {
        ects[course.position] += course.credits
        ects['sum'] += course.credits
      })
      return ects
    }

    const ectsTopics = () => {
      let tmp = JSON.parse(JSON.stringify(topcs))
      courses().forEach(course => {
        course.topics.forEach(topic => {
          tmp[topic.topicId].credits = tmp[topic.topicId].credits ? tmp[topic.topicId].credits + (topic.ectsPer * course.credits) : (topic.ectsPer * course.credits)
        })
      })
      return tmp
    }

    const ectsAreas = () => {
      let tmp = JSON.parse(JSON.stringify(areas))
      ectsTopics().forEach(topic => {
        if (topic.credits) {
          tmp[topic.areaId].credits = tmp[topic.areaId].credits ? tmp[topic.areaId].credits + topic.credits : topic.credits
        }
      })
      return tmp
    }

    const round = (value, precision) => {
      var multiplier = Math.pow(10, precision || 0)
      return Math.round(value * multiplier) / multiplier
    }

    const ectsAreasSeries = ectsAreas().map(area => {return area.hasOwnProperty('credits') ? round((area.credits*100)/ectsSum()['sum'],1) : 0})

    const chartData = [
      { name: ectsAreas()[0].name, value: ectsAreasSeries[0] },
      { name: ectsAreas()[1].name, value: ectsAreasSeries[1] },
      { name: ectsAreas()[2].name, value: ectsAreasSeries[2] },
      { name: ectsAreas()[3].name, value: ectsAreasSeries[3] },
      { name: ectsAreas()[4].name, value: ectsAreasSeries[4] },
      { name: ectsAreas()[5].name, value: ectsAreasSeries[5] },
      { name: ectsAreas()[6].name, value: ectsAreasSeries[6] }
    ]

    const chartColors = ectsAreas().map(area => {return area.color})

    const chartTooltip = ({ active, payload, label }) => {
        if (active) {
            return (<div className="chart-tooltip">{payload[0].name}: <b>{payload[0].value}%</b></div>)
        }
        return null
    }

    const chartLabel = ({ name, cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      if (percent === 0) return ""
      const RADIAN = Math.PI / 180
      const radius = innerRadius + (outerRadius - innerRadius) * 0.75
      const x = cx + radius * Math.cos(-midAngle * RADIAN)
      const y = cy + radius * Math.sin(-midAngle * RADIAN)
      return (
        <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      )
    }

    const supportedRoles = () => {
      let output =
      roles.sort((a,b) => {
        const bandA = a.role.toUpperCase()
        const bandB = b.role.toUpperCase()
        let comparison = 0
        if (bandA > bandB) {
          comparison = 1
        } else if (bandA < bandB) {
          comparison = -1
        }
        return comparison
      })
      .map((role, index) => {
        let ok = true
        role.comp.every((comp) => {
          if (!compIds().includes(comp)) {
            ok = false
            return false
          } else {
            return true
          }
        })
        return ok === false ? null : (
          <div key={index}>
            <div className="work-role hover" data-for={"ReactTooltip-" + index} data-tip>
              &nbsp;•&nbsp;{role.role}
            </div>
            <ReactTooltip className="tooltip" id={"ReactTooltip-" + index} place="top" effect="solid">
              <h6>Required Competencies:</h6>
              <ul>
              {role.comp.map((item, index) => {
                return <li key={index}>{comps[item]}<br/></li>
              })}
              </ul>
            </ReactTooltip>
          </div>
        )
      })
      if (!output.some((el) => {return el !== null})) {
        output = <div className="work-role" >&nbsp;&nbsp;&nbsp;&nbsp;None</div>
      }
      return output
    }

    const niceCompetencies = () => {
      let output = compIds().map((compId,index) => {
        return <div className="work-role" key={index}>&nbsp;•&nbsp;{comps[compId]}</div>
      })
      if (!output.some((el) => {return el !== null})) {
        output = <div className="work-role" >&nbsp;&nbsp;&nbsp;&nbsp;None</div>
      }
      return output
    }

    const supportedECSFProfiles = () => {
      let output =
      ecsfProfiles.sort((a,b) => {
        const bandA = a.profile.toUpperCase()
        const bandB = b.profile.toUpperCase()
        let comparison = 0
        if (bandA > bandB) {
          comparison = 1
        } else if (bandA < bandB) {
          comparison = -1
        }
        return comparison
      })
      .map((profile, index) => {
        let ok = true
        profile.skills.every((skill) => {
          if (!ecsfSkillIds().includes(skill)) {
            ok = false
            return false
          } else {
            return true
          }
        })
        profile.knowledges.every((knowledge) => {
          if (!ecsfKnowledgeIds().includes(knowledge)) {
            ok = false
            return false
          } else {
            return true
          }
        })
        return ok === false ? null : (
          <div key={index}>
            <div className="work-role hover" data-for={"ReactTooltip2-" + index} data-tip>
              &nbsp;•&nbsp;{profile.profile}
            </div>
            <ReactTooltip className="tooltip" id={"ReactTooltip2-" + index} place="top" effect="solid">
              <h6>Required Skills:</h6>
              <ul>
              {
                profile.skills.map((item, index) => {
                  return <li key={index}>{ecsfSkills[item]}<br/></li>
                })
              }
              </ul>
              <h6>Required Knowledge:</h6>
              <ul>
              {
                profile.knowledges.map((item, index) => {
                  return <li key={index}>{ecsfKnowledge[item]}<br/></li>
                })
              }
              </ul>
            </ReactTooltip>
          </div>
        )
      })
      if (!output.some((el) => {return el !== null})) {
        output = <div className="work-role" >&nbsp;&nbsp;&nbsp;&nbsp;None</div>
      }
      return output
    }

    return (
      <Col className="statistics" lg ="12" xl="4">
        <h3 className="section-title"> Statistics</h3>
        <div className="content">

          {courses().length > 0 ? (
          <div className="content-scroll">

            <h6>Total ECTS credits: </h6>

            <Table className="ects-overview" size="sm">
              <thead>
                <tr>
                  <th></th>
                  <th>winter</th>
                  <th>summer</th>
                  <th>sum</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1st year</th>
                  <td>{ectsSum()['1-w']}</td>
                  <td>{ectsSum()['1-s']}</td>
                  <td>{ectsSum()['1-w'] + ectsSum()['1-s']}</td>
                </tr>
                <tr>
                  <th>2nd year</th>
                  <td>{ectsSum()['2-w']}</td>
                  <td>{ectsSum()['2-s']}</td>
                  <td>{ectsSum()['2-w'] + ectsSum()['2-s']}</td>
                </tr>
                <tr>
                  <th>3rd year</th>
                  <td>{ectsSum()['3-w']}</td>
                  <td>{ectsSum()['3-s']}</td>
                  <td>{ectsSum()['3-w'] + ectsSum()['3-s']}</td>
                </tr>
                <tr>
                  <th >sum</th>
                  <td></td><td></td>
                  <td ><b>{ectsSum()['sum']}</b></td>
                </tr>
              </tbody>
            </Table>

            <Tabs defaultActiveKey="nist" transition={false} className="stats-tabs">
              <Tab eventKey="nist" title="NIST NICE Framework">

                <h6>ECTS by SPARTA areas:</h6>

                {ectsAreasSeries.reduce((a, b) => a + b, 0) > 0 ? (
                  <ResponsiveContainer width="100%" height={296} className="chart-container">
                    <PieChart width={400} height={400}>
                      <Tooltip content={chartTooltip}/>
                      <Legend />
                      <Pie
                        animationBegin={0}
                        animationDuration={500}
                        animationEasing="ease"
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        legendType="circle"
                        label={chartLabel}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {chartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="work-role" >&nbsp;&nbsp;&nbsp;&nbsp;None</div>
                )}

                <h6>ECTS by SPARTA topics:</h6>

                {ectsAreasSeries.reduce((a, b) => a + b, 0) > 0 ? (
                  <Table className="ects-topics" size="sm">
                    <thead>
                      <tr>
                        <th>Topic</th>
                        <th>ECTS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ectsTopics().map((topic,index) => {
                        return topic.credits ? <tr key={index}><td>{topic.name}</td><td>{round((topic.credits*100)/ectsSum()['sum'],1) + "%"}</td></tr> : null
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div className="work-role" >&nbsp;&nbsp;&nbsp;&nbsp;None</div>
                )}

                <h6>NICE Competencies:</h6>
                {niceCompetencies()}

                <h6>Supported Work Roles according to NIST:</h6>
                {supportedRoles()}

              </Tab>

              <Tab eventKey="ecsf" title={<>ECSF Framework <Badge variant="light" className="beta-light">Beta</Badge></>}>

                <h6>Supported ECSF Profiles:</h6>
                {supportedECSFProfiles()}

              </Tab>

            </Tabs>

          </div>
          ) : (
            <div className="empty-data">
              <font className="header">There are no courses yet.</font><br/>Drag some course to your curricula.
            </div>
          )}
        </div>

      </Col>
    )
  }
}

export default Statistics
