import React from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import { Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import dropImg from '../../assets/drop-here-trainings.png'

import Training from './Training'

const TrainingsList = styled.div`
  padding: 5px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : '#ececec'};
  flex-grow: 1;
  min-height: 117px;
  border-radius: 5px;
  margin: 0px 5px 15px 0px;
  text-align: center;
`

class TimeTable extends React.Component {

  exportJsonFile = () => {
    let trainings = {}
    for (const property in this.props.trainings) {
      trainings[property] = this.props.trainings[property]
    }
    const content = {
      trainings: trainings,
      columns: this.props.columns
    }
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(content)], {type: 'application/json'});
    element.href = URL.createObjectURL(file);
    element.download = "my-curricula.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  importJsonFile = (event) => {
    var file = event.target.files[0]
    let self = this
    if (file.type.match("application/json")) {
      var reader = new FileReader()
      reader.onload = function(e) {
        self.props.importData(reader.result)
      }
      reader.readAsText(file)
    }
  }

  render(){

    const printDroppable = (column) => {
      return (
        <Droppable
          droppableId={column.id}
          isDropDisabled={false}
        >
          {(provided, snapshot) => (
            <TrainingsList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {column.trainingIds.map((training, index) => {
                return <Training key={this.props.trainings[training].id} training={this.props.trainings[training]} index={index} removeTraining={this.props.removeTraining} openAddModal={this.props.openAddModal}
                columnId={column.id}/>
              })}
              {provided.placeholder}
              {column.trainingIds.length === 0 ? <img src={dropImg} className="dropImg" alt='dropHere'/> : ''}
            </TrainingsList>
          )}
        </Droppable>
      )
    }

    return (
      <Col xs lg="6" xl="4" className="time-table">
        <h3 className="section-title"> Your Trainings</h3>
        <div className="content">
          <div className="content-scroll">

            <div className="buttons-container">
              <input type="file" id="fileImport" accept="application/JSON" onChange={this.importJsonFile}/>
              <Button variant="outline-success" disabled onClick={() => document.getElementById("fileImport").click()}>Import</Button>
              <Button variant="outline-danger" onClick={this.exportJsonFile}>Export</Button>
            </div>

            <Container>
              <Row>
                <Col>
                  {printDroppable(this.props.columns['selected'])}
                </Col>
              </Row>
            </Container>

          </div>
        </div>

      </Col>
    )
  }
}

export default TimeTable
