const enisaProfilesMap =
[
    {
        "name": "Chief Information Security Officer (Ciso)",
        "id": 1,
        "skillIds": [
            1,
            6,
            13,
            14,
            16,
            19,
            21,
            22,
            23,
            24,
            26,
            28,
            31
        ]
    },
    {
        "name": "Cyber Incident Responder",
        "id": 2,
        "skillIds": [
            2,
            6,
            12,
            16,
            18,
            27,
            30,
            31
        ]
    },
    {
        "name": "Cyber Legal, Policy & Compliance Officer",
        "id": 3,
        "skillIds": [
            2,
            3,
            4,
            5,
            16
        ]
    },
    {
        "name": "Cyber Threat Intelligence Specialist",
        "id": 4,
        "skillIds": [
            3,
            12,
            15,
            17,
            25,
            26,
            27,
            28,
            29,
            30
        ]
    },
    {
        "name": "Cybersecurity Architect",
        "id": 5,
        "skillIds": [
            2,
            4,
            5,
            10,
            13,
            24,
            25,
            28,
            31
        ]
    },
    {
        "name": "Cybersecurity Auditor",
        "id": 6,
        "skillIds": [
            8,
            14,
            16,
            31
        ]
    },
    {
        "name": "Cybersecurity Educator",
        "id": 7,
        "skillIds": [
            2,
            9,
            16,
            19
        ]
    },
    {
        "name": "Cybersecurity Implementer",
        "id": 8,
        "skillIds": [
            2,
            10,
            13,
            14,
            18,
            25,
            29,
            30
        ]
    },
    {
        "name": "Cybersecurity Researcher",
        "id": 9,
        "skillIds": [
            2,
            5,
            16,
            22,
            23,
            25,
            27,
            28,
            29
        ]
    },
    {
        "name": "Cybersecurity Risk Manager",
        "id": 10,
        "skillIds": [
            5,
            19,
            24,
            26,
            29,
            30,
            31
        ]
    },
    {
        "name": "Digital Forensics Investigator",
        "id": 11,
        "skillIds": [
            2,
            8,
            16,
            27,
            29,
            30
        ]
    },
    {
        "name": "Penetration Tester",
        "id": 12,
        "skillIds": [
            2,
            22,
            25,
            27,
            28,
            29
        ]
    }
]
export default enisaProfilesMap
