 // let server = "127.0.0.1/rewire_csprofiler_server"
// let server = "147.229.146.226/rewire_csprofiler_server"
let server = "https://csprofiler.informacni-bezpecnost.cz/data"

export const Config = {
	appRoot	:	'/',
	server	: {
		"getAllData"						    : server+"/getAllData.php",
		"addCertification"			    : server+"/addCertification.php",
    "analyzeCourseDescription"  : server+"/analyzeCourseDescription.php",
		"editCertification"			    : server+"/editCertification.php",
		"deleteCertification"		    :	server+"/deleteCertification.php",
		"addStudyProgram"				    : server+"/addStudyProgram.php",
	  "editStudyProgram"			    : server+"/editStudyProgram.php",
    "deleteStudyProgram"		    :	server+"/deleteStudyProgram.php",
	  "addTraining"					      : server+"/addTraining.php",
		"editTraining"              : server+"/editTraining.php",
		"deleteTraining"				    :	server+"/deleteTraining.php",
		"addSkillsGroup"				    : server+"/addSkillsGroup.php",
    "editSkillsGroup"           : server+"/editSkillsGroup.php",
		"deleteSkillsGroup"			    :	server+"/deleteSkillsGroup.php",
		"register"							    :	server+"/register.php",
		"forgotPassword"				    :	server+"/forgotPassword.php",
		"login"									    :	server+"/login.php",
		"logout"								    :	server+"/logout.php",
		"editUser"                  :	server+"/editUser.php",
		"getUsersData"              : server+"/getUsersData.php",
		"updateUsersData"           :	server+"/updateUsersData.php",
		"visitorFeedback"           : server+"/visitorFeedback.php",
		"visitorStats"              : server+"/visitorStats.php"
	}
}
