import React from 'react'
import { Config } from '../../config.js'
import Axios from 'axios'
import { Alert, Button, Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import validateColor from 'validate-color'

import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'


class RewireFramework extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: null,
      isLoading: false,
      formValidated: false,
      deleteGroupModal: null
    }
  }

  // delete row from the table
  deleteRow = () => {
    this.setState({deleteGroupModal: null}) //close confirm modal
    Axios.post( Config.server.deleteSkillsGroup, {...this.props.authData, id: this.state.deleteGroupModal}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        let responseData = response.data
        if (responseData.error === false) {
          this.props.showMessage({type: 'success', content: "Data updated successfully."})
          this.props.getPublicData()
        } else {
          this.props.showMessage({type: 'error', content: "Something went wrong!"})
          console.log(responseData.message)
        }
      })
      .catch((error) => {
        console.log(error)
        this.props.showMessage({type: 'error', content: "Unable to connect to database."})
      })
  }

  // cancel and hide form
  cancelUpload = () => {
    this.setState({formData: null, formValidated: false})
  }

  trimObject = (obj) => {
    let trimmed = JSON.stringify(obj, (key, value) => {
      if (typeof value === 'string') {
        return value.trim()
      }
      return value
    });
    return JSON.parse(trimmed)
  }

  // save form data of new or edited table row
  saveForm = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({formValidated: true})

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    this.setState({isLoading: true, formValidated: false, })

    // prepare data to send to db
    let values = this.trimObject(JSON.parse(JSON.stringify(this.state.formData)))
    values.specific_skills = JSON.stringify(this.trimObject(values.specific_skills))
    values.specific_knowledge = JSON.stringify(this.trimObject(values.specific_knowledge))
    if (!validateColor(values.color)) values.color = "transparent"

    Axios.post( this.state.formData.hasOwnProperty('id') ? Config.server.editSkillsGroup : Config.server.addSkillsGroup, {...this.props.authData, ...values}, {headers: { 'Content-Type': 'application/json' }})
      .then((response) => {
        let responseData = response.data
        if (responseData.error === false) {
          this.setState({isLoading: false, formData: null})
          this.props.showMessage({type: 'success', content: "Data updated successfully."})
          this.props.getPublicData()
        } else {
          this.setState({isLoading: false})
          this.props.showMessage({type: 'error', content: responseData.message.includes("already exists") ? "This skill group already exists!" : "Something went wrong!"})
          console.log(responseData.message)
        }
      })
      .catch((error) => {
        console.log(error)
        this.setState({isLoading: false})
        this.props.showMessage({type: 'error', content: "Unable to connect to database."})
      })
  }

  // add new spec. skill or knowledge to the skill group
  addSpecItem = (type) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    newData[type].push({name: "", action: "add"})
    this.setState({formData: newData})
  }

  // delete spec. skill or knowledge from the skill group
  deleteSpecItem = (item, type, index) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    if (newData[type][index].action === "add") {
      // its new item, delete just from this form
      newData[type].splice(index,1)
    } else {
      // its stored item, remove it from the db
      newData[type][index].action = "delete"
    }
    this.setState({formData: newData})
  }

  // catch form change event and save new data
  changeFormData = (event, item, index) => {
    let newData = JSON.parse(JSON.stringify(this.state.formData))
    if (event.target.id.includes("specific-skills") || event.target.id.includes("specific-knowledge")) {
      newData[event.target.id.includes("specific-skills") ? "specific_skills" : "specific_knowledge"][index] = {
        name: event.target.value,
        id: item.id ? item.id : null,
        action: item.action === "none" ? "update" : item.action
      }
    } else {
      newData[event.target.id] = event.target.value
    }
    this.setState({formData: newData})
  }

  // sets the form data when start editing some skill group
  openEditForm = (skillGroup) => {
    let newData = JSON.parse(JSON.stringify(skillGroup))
    newData.specific_skills = newData.specific_skills.map(skillId => { return{...this.props.rsSpecificSkills.find(i => i.id === skillId), action: "none"}})
    newData.specific_knowledge = newData.specific_knowledge.map(knowledgeId => { return{...this.props.rsSpecificKnowledge.find(i => i.id === knowledgeId), action: "none"}})
    this.setState({formData: newData})
  }


  render(){
    const { rsSkillsGroup, rsSpecificKnowledge, rsSpecificSkills } = this.props
    const { formValidated, formData, isLoading, deleteGroupModal } = this.state

    // sort and prepare db data to display in the table
    const data = this.props.rsSkillsGroup
    if (Array.isArray(this.props.rsSkillsGroup)) data.sort((a, b) => a.name.localeCompare(b.name))

    // just for form initialization purposes
    const emptyForm = {
      name : "",
      color: "",
      specific_skills: [],
      specific_knowledge: []
    }

    return (
      <div className="content-scroll">
        <div className="study-programs">
          {
            data === null || isLoading ? (
               <div className="main_loading"><Spinner animation="border" variant="primary" /><br/>Loading...</div>
            ) : (
              !Array.isArray(data) || data === 0 ? (
                <div className="main_loading"><Alert variant="danger">Sorry, something went wrong.</Alert></div>
              ) : (
                data.length === 0 ? (
                  <div className="main_loading"><Alert variant="info">No data in the database.</Alert></div>
                ) : (

                  formData !== null ? (

                    <div className="upload_form">
                      <h4>{formData.hasOwnProperty("created") ? "Edit skill group" : "Add new skill group"}</h4>

                      <Form noValidate validated={formValidated} onSubmit={this.saveForm} >

                        <Form.Group as={Row} controlId="name">
                          <Form.Label column sm={3}>Skill group name *</Form.Label>
                          <Col sm={9}>
                            <Form.Control required value={formData.name} onChange={this.changeFormData}/>
                            <Form.Control.Feedback type="invalid">
                            Please provide a valid name of the skill group.
                            </Form.Control.Feedback>
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="color">
                          <Form.Label column sm={3}>CSS color</Form.Label>
                          <Col sm={7}>
                            <Form.Control value={formData.color} onChange={this.changeFormData} placeholder="transparent"/>
                            <Form.Text className="text-muted">
                              Use name, html, hex, rgb, rgba, hsl, hsla, hwb, lab or lch values.
                            </Form.Text>
                          </Col>
                          <Col sm={2}>
                            <div className="color-box" style={{backgroundColor: validateColor(formData.color) ? formData.color : "transparent"}}><span>preview</span></div>
                          </Col>
                        </Form.Group>

                        {formData.specific_skills.map((item, indx) => {
                          return item.action === "delete" ? null : (
                            <Form.Group as={Row} key={indx} className="form-skills-label">
                              <Form.Label column sm={3} >Specific skills</Form.Label>
                              <Col sm={7}>
                                <Form.Control as="textarea" rows={2} id={"specific-skills-"+(indx+1)} key={indx} value={item.name} required onChange={(e)=>this.changeFormData(e, item, indx)}/>
                                <Form.Control.Feedback type="invalid">
                                Please provide a valid name of the specific skill.
                                </Form.Control.Feedback>
                              </Col>
                              <Col sm={2} className="row-delete-button">
                                <Button variant="outline-danger" size="sm" onClick={() => this.deleteSpecItem(item, "specific_skills", indx)}>Delete skill</Button>
                              </Col>
                            </Form.Group>
                          )
                        })}

                        <Form.Group as={Row} className="form-skills-label">
                          <Form.Label column sm={3}>Specific skills</Form.Label>
                          <Col sm={9} className="row-button">
                            <Button variant="outline-success" size="sm" onClick={()=>this.addSpecItem("specific_skills")}>Add skill</Button>
                          </Col>
                        </Form.Group>

                        {formData.specific_knowledge.map((item, indx) => {
                          return item.action === "delete" ? null : (
                            <Form.Group as={Row} key={indx} className="form-knowledge-label">
                              <Form.Label column sm={3} >Specific knowledge</Form.Label>
                              <Col sm={7}>
                                <Form.Control as="textarea" rows={2} id={"specific-knowledge-"+(indx+1)} key={indx} value={item.name} required onChange={(e)=>this.changeFormData(e, item, indx)}/>
                                <Form.Control.Feedback type="invalid">
                                Please provide a valid name of the specific knowledge.
                                </Form.Control.Feedback>
                              </Col>
                              <Col sm={2} className="row-delete-button">
                                <Button variant="outline-danger" size="sm" onClick={() => this.deleteSpecItem(item, "specific_knowledge", indx)}>Delete knowledge</Button>
                              </Col>
                            </Form.Group>
                          )
                        })}

                        <Form.Group as={Row} className="form-knowledge-label">
                          <Form.Label column sm={3}>Specific knowledge</Form.Label>
                          <Col sm={9} className="row-button">
                            <Button variant="outline-success" size="sm" onClick={()=>this.addSpecItem("specific_knowledge")}>Add knowledge</Button>
                          </Col>
                        </Form.Group>

                        <div className="upload_buttons">
                          <Button variant="danger" onClick={this.cancelUpload}>Cancel</Button>
                          <Button type="submit" variant="success" >Confirm</Button>
                        </div>

                      </Form>
                    </div>

                  ) : (

                    <div className="overview">
                      <h4>REWIRE Skill Framework</h4>
                      <Button size="sm" variant="outline-primary" className="header-button" onClick={()=>this.setState({formData: emptyForm})}>+ Add Skill Group</Button>
                      <Table bordered className="skills-group overview">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>REWIRE skill group</th>
                            <th>ENISA skills</th>
                            <th>ENISA knowledge</th>
                            <th className="action-column">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rsSkillsGroup.map((skillGroup, index) => {
                            let rows = Math.max(skillGroup.specific_knowledge.length, skillGroup.specific_skills.length)
                            rows = rows ? rows : 1
                            return Array.from(Array(rows)).map((e,indx) => {
                              return (
                                <tr key={index+"-"+indx} style={{backgroundColor: skillGroup.color}} className={rows === 1 ? "first-row last-row" : indx === 0 ? "first-row" : indx === rows-1 ? "last-row" : "" }>
                                  {indx === 0 ? (
                                    <td rowSpan={rows}>{skillGroup.id}</td>
                                  ) : null}
                                  {indx === 0 ? (
                                    <td rowSpan={rows} className="middle-column">{skillGroup.name}</td>
                                  ) : null}
                                  <td className="middle-column">
                                    {skillGroup.specific_skills.length > indx ? (
                                      rsSpecificSkills.find(x => x.id === skillGroup.specific_skills[indx]).name
                                    ) : null}
                                  </td>
                                  <td className="middle-column">
                                    {skillGroup.specific_knowledge.length > indx ? (
                                      rsSpecificKnowledge.find(x => x.id === skillGroup.specific_knowledge[indx]).name
                                    ) : null}
                                  </td>
                                  {indx === 0 ? (
                                    <td rowSpan={rows} className="actionTableColumn">
                                      <EditOutlinedIcon fontSize="small" className="editIcon" data-for={"Tooltip-action-edit"} data-tip onClick={()=> this.openEditForm(skillGroup)}/>
                                      <ReactTooltip id={"Tooltip-action-edit"} place="top" effect="solid">
                                        Edit record
                                      </ReactTooltip>
                                      <DeleteOutlineIcon fontSize="small" className="deleteIcon" data-for={"Tooltip-action-delete"} data-tip onClick={()=> this.setState({deleteGroupModal: skillGroup.id})} />
                                      <ReactTooltip id={"Tooltip-action-delete"} place="top" effect="solid">
                                        Delete record
                                      </ReactTooltip>
                                    </td>
                                  ) : null }
                                </tr>
                              )
                            })
                          })}
                        </tbody>
                      </Table>

                      <Modal
                        show={deleteGroupModal !== null}
                        onHide={()=>this.setState({deleteGroupModal: null})}
                        centered
                        className="delete-row-modal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p><HelpOutlineIcon/>Are you sure you want to delete this skill group?</p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={()=> this.setState({deleteGroupModal: null})}>No</Button>
                          <Button variant="primary" onClick={this.deleteRow}>Yes</Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  )
                )
              )
            )
          }
        </div>
      </div>
    )

  }
}

export default RewireFramework
