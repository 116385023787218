import React from 'react'
import {Config} from '../../config.js'
import Axios from 'axios'
import {createHash} from 'crypto'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'


const EditUserModal = ({ setUser, user, visible, switchLogin, onCancel, showMessage, authData }) => {
  const [loading, setLoading] = React.useState(false)
  const [validated, setValidated] = React.useState(false)
  const [formData, setFormData] = React.useState({name: "", company: "", email: "", password: ""})
  const [openUser, setOpenUser] = React.useState(false)

  // set default form values
  if (user !== openUser && visible) {
    setFormData({name: user.name, company: user.company, email: user.email, password: ""})
    setOpenUser(user)
  }

  function submit(event){
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    }
    setValidated(false)
    setLoading(true)
    let values = JSON.parse(JSON.stringify(formData))
    values.password = values.password ? createHash('sha256').update(values.password).digest('hex') : ""
    values.id = user.id

    Axios.post( Config.server.editUser, {...authData, ...values}, {headers: { 'Content-Type': 'application/json' }})
    .then((response) => {
      setLoading(false)
      if (response && response.data.error === false){
        setFormData({name: "", company: "", email: "", password: ""})
        showMessage({type: 'success', content: "Updated successfully."})
        if (response.data.user.token === "") response.data.user.token = user.token
        setUser({...response.data.user, password: values.password ? values.password : user.password})
        switchLogin('')
      } else {
        console.log("error: " + response.data.message)
        showMessage({type: 'error', content: "Something went wrong!"})
      }
    })
    .catch((error) => {
      setLoading(false)
      console.log(error)
      showMessage({type: 'error', content: "Something went wrong!"})
    })
  }

  function formChange(event) {
    setFormData({...formData, [event.target.id]: event.target.value})
  }


  return (
    <Modal
      show={visible}
      onHide={onCancel}
      backdrop="static"
      centered
      className="user-modal"
      id="edit-user-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit your profile</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <br/>
        <Form noValidate validated={validated} onSubmit={submit} autoComplete="off">

          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3">
              Name
            </Form.Label>
            <Col sm="9">
              <Form.Control type="name" placeholder="Enter your name" value={formData.name} onChange={formChange} autoComplete="off"/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="company">
            <Form.Label column sm="3">
              Institution
            </Form.Label>
            <Col sm="9">
              <Form.Control type="company" placeholder="Enter your institution" value={formData.company} onChange={formChange} autoComplete="off"/>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="email">
            <Form.Label column sm="3">
              E-mail *
            </Form.Label>
            <Col sm="9">
              <Form.Control type="email" placeholder="Enter your e-mail" required  disabled value={formData.email} onChange={formChange} autoComplete="off"/>
              <Form.Control.Feedback type="invalid">Please enter a valid e-mail address.</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="password">
            <Form.Label column sm="3">
              Password *
            </Form.Label>
            <Col sm="9">
              <Form.Control type="password" placeholder="Your current password"  value={formData.password} onChange={formChange} autoComplete="new-password"/>
              <Form.Text className="text-muted">
              Leave empty if you don't want to change the password!
              </Form.Text>
            </Col>
          </Form.Group>

          <br/>

          <Form.Group style={{textAlign: "center"}}>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <><Spinner as="span" animation="border" size="sm" role="status"/>&nbsp;&nbsp;Loading...</>
              ): ( "Save" )}
            </Button>
            &nbsp;&nbsp;
            <Button variant="outline-secondary" onClick={()=>switchLogin("")}>
              Cancel
            </Button>
          </Form.Group>

        </Form>
      </Modal.Body>

    </Modal>
  )
}

export default EditUserModal
